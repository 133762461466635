<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cupons</h1>
    </v-col>
    <v-col cols="12" v-if="coupons && coupons.data.length">
      <v-item-group v-model="cartCoupon">
        <v-row dense>
          <template v-for="coupon in coupons.data">
            <v-item :value="coupon.id" v-slot:default="{ active, toggle }" :key="coupon.id">
              <v-col cols="12" md="4" lg="3" xl="2">
                <v-card flat outlined :dark="active" :class="active ? 'primary' : ''" @click="toggle">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title v-if="coupon.type === 'DECIMAL'">
                        {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(coupon.amount) }} de desconto
                      </v-list-item-title>
                      <v-list-item-title v-if="coupon.type === 'PERCENTAGE'">
                        {{ coupon.percentage }}% de desconto
                      </v-list-item-title>
                      <v-list-item-title v-if="coupon.type === 'DELIVERY_FREE'">
                        Frete Grátis
                      </v-list-item-title>
                      <v-list-item-title v-if="coupon.type === 'FIRST_ORDER_FREE'">
                        Primeira Compra Grátis
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="border-style: dashed"/>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="coupon.min_amount">
                        Valor Mínimo: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(coupon.min_amount) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="coupon.max_amount">
                        Valor Máximo: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(coupon.max_amount) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Quantidade: {{ coupon.redeemed }} de {{ coupon.quantity }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Valido até {{ DateTime.fromSQL(coupon.valid_until).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-item>
          </template>
        </v-row>
      </v-item-group>
    </v-col>
    <v-col cols="12" v-else>
      <v-alert border="left" colored-border color="primary">
        <v-row dense align="center">
          <v-col class="shrink">
            <v-icon color="primary" size="36">mdi-ticket-percent</v-icon>
          </v-col>
          <v-col class="grow">
            <span class="d-block">Você não possui cupons válidos para essa compra!</span>
            <span class="d-block text-caption">Resgate cupons sempre que possível nas páginas dos estabelecimentos.</span>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>

    <v-col cols="12" class="pt-4 text-right">
      <v-btn text class="mr-1" @click="$router.back()">Voltar</v-btn>
      <v-btn depressed color="primary" @click="makeOrder">Finalizar</v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-cart-coupon',
    data: () => ({
      coupons: null,
      DateTime:DateTime,
    }),
    async created() {
      const coupons = await this.$axios.get(`/users/${this.user.id}/coupons`, { params: { all: 1, valid: 1, amount: this.cartTotal, business_id: this.cartBusiness } })
      this.coupons = coupons.data;
    },
    methods: {
      makeOrder() {
        this.$store.dispatch('makeCartOrder', {
          user: this.user.id
        }).then(() => {
          this.$store.dispatch('clearCartItems');
          this.$router.push({ name: 'orders' });
        });
      },
    },
    computed: {
      user() {
        return this.$store.getters.getLoginAuthUser;
      },
      cartBusiness() {
        return this.$store.getters.getCartBusiness;
      },
      cartTotal() {
        return this.$store.getters.getCartTotal;
      },
      cartCoupon: {
        get: function () {
          return this.$store.getters.getCartCoupon;
        },
        set: function (status) {
          this.$store.dispatch('setCartCoupon', status);
        },
      },
    },
  };
</script>
