import AMount from './index'

export default [
  {
    path: '/businesses/:business/items/:item/mount',
    name: 'mount',
    component: AMount,
    meta: {
      requiresAuth: true,
    },
  },
];
