<template>
  <v-row align-content="center" justify="center" class="fill-height">
    <v-col cols="12" sm="8" md="4" style="max-width: 400px;">
      <v-card flat outlined>
        <v-card-title>Recuperar Senha</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" @keyup.native.enter="resetPassword">
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="credentials.email" label="Email*" type="email"
                              hide-details="auto" :rules="[$rules.required]"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$router.back()">Voltar</v-btn>
          <v-btn depressed @click="resetPassword" color="primary">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-reset-password',
  data: () => ({
    credentials: {
      email: '',
    },
    valid: true,
  }),
  methods: {
    resetPassword() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch('resetPassword', this.credentials)
          .then(() => {
            this.$refs.form.reset();
            this.$store.dispatch('loginRemoveSession');
            this.$router.push({ name: 'email_alert' });
          }).catch(() => {
            alert('Erro no Resetar Senha, Tente Novamente!');
          });
      }
    },
  },
};
</script>
