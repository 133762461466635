<template>
  <v-row dense align-content="start" class="fill-height">
    <template v-if="cartBusiness && business">
      <!--Header-->
      <v-col cols="12">
        <a-cart-header-tile :business="business.data"/>
      </v-col>
      <!--Service Alert-->
      <v-col cols="12">
        <a-service-alert/>
      </v-col>
    </template>
    <v-col cols="12">
      <router-view/>
    </v-col>
  </v-row>
</template>

<script>
import AServiceAlert from "@/components/AServiceAlert";
import ACartHeaderTile from "@/components/ACartHeaderTile";

export default {
  name: 'a-cart',
  components: {
    ACartHeaderTile,
    AServiceAlert
  },
  data: () => ({
    business: null
  }),
  async created() {
    if (this.cartBusiness) {
      const business = await this.$axios.get(`/businesses/${this.cartBusiness}`);
      this.business = business.data;
      await this.$store.dispatch('setCartDeliveryTax', business.data.data.delivery_tax);
      await this.$store.dispatch('setCartMinOrderAmount', business.data.data.min_order_amount);
      await this.$store.dispatch('setCartPaymentMethods', business.data.data.payment_methods);
    }
  },
  computed: {
    cartBusiness() {
      return this.$store.getters.getCartBusiness;
    },
  },
};
</script>
