<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat outlined>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <h1 class="text-subtitle-1 font-weight-light text-uppercase">Avaliação</h1>
              </v-col>
              <v-col cols="12">
                Avalie seu pedido, quantas estrelas o estabelecimento merece?
              </v-col>
              <v-col cols="12">
                <v-rating dense background-color="gray lighten-1" v-model="payload.rating"/>
              </v-col>
              <v-col cols="12">
                Conte como foi sua experiência, a qualidade da comida ou produto, a rapidez na entrega ou deixe alguma sujestão de melhoria.
              </v-col>
              <v-col cols="12">
                <v-textarea auto-grow rows="1" v-model="payload.comment" label="Comentário*"
                            :error-messages="formErrors.cep"
                            hide-details="auto"/>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="$refs.form.reset()">Limpar</v-btn>
          <v-btn depressed color="primary" @click="storeRating()">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'a-rating',
    data: () => ({
      formErrors: {},
      payload: {
        rating: 0,
        comment: ''
      },
      valid: true
    }),
    methods: {
      async storeRating() {
        if (!this.payload.rating > 0) {
          alert('Selecione pelo menos uma estrela!');
          return;
        }
        if (!this.$refs.form.validate()) {
          alert('Verifique os campos do formulário!');
          return;
        }
        try {
          await this.$axios.post(`/users/${this.user.id}/orders/${this.$route.params.order}/ratings`, this.payload)
          this.$refs.form.reset();
          this.$router.back();
        } catch (error) {
          if (error.response && error.response.status === 422) {
            this.formErrors = error.response.data.errors;
          }
        }
      },
    },
    computed: {
      user() {
        return this.$store.getters.getLoginAuthUser;
      }
    }
  };
</script>
