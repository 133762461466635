<template>
  <v-list-item two-line v-bind="$attrs">
    <v-list-item-avatar rounded :size="imageSize">
      <v-img :src="business.avatar ? business.avatar.url : require('../assets/placeholder.png')" aspect-ratio="1">
        <v-row dense>
          <v-col cols="12" v-if="!business.online" class="d-flex justify-center align-center fill-height white--text" style="background-color: rgba(0, 0, 0, .7)">
            <span>Fechado</span>
          </v-col>
        </v-row>
      </v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ business.name }}</v-list-item-title>
      <v-list-item-subtitle>
        <v-icon x-small color="primary" class="pb-1">icon-star</v-icon> {{ business.rating }}
        <template v-if="business.distance">
          <v-icon x-small color="primary" class="pb-1">mdi-map-marker</v-icon> {{ locationFormat(business.distance) }}
        </template>
        <v-icon x-small color="primary" class="pb-1">mdi-tag</v-icon> {{ business.category.name }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-icon x-small color="primary" class="pb-1">icon-take-away</v-icon> {{ business.delivery_min_time }} - {{ business.delivery_max_time }} min
        <template v-if="business.delivery_tax > 0">
          <v-icon x-small color="primary" class="pb-1">icon-shopping-bag</v-icon> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(business.delivery_tax) }}
        </template>
        <template v-else>
          <v-icon x-small color="primary" class="pb-1">icon-shopping-bag</v-icon> <span style="color: green">Grátis</span>
        </template>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="business.min_order_amount">
        <v-icon x-small color="primary" class="pb-1">icon-coin</v-icon> Pedido mín {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(business.min_order_amount) }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-icon color="primary" v-if="business.has_promotion">mdi-sale</v-icon>
      <v-icon color="primary" v-if="business.has_coupon">mdi-ticket-percent</v-icon>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-business-tile',
    props: {
      business: Object,
      imageSize: {
        type: Number,
        default: 80
      },
    },
    methods: {
      locationFormat(distance) {
        let distanceInMeters = distance / 1000;
        return distanceInMeters >= 1 ? distanceInMeters.toFixed(1) + ' km' : distance.toFixed(0) + ' m';
      },
    },
  }
</script>
