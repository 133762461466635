<template>
  <v-row dense align-content="start" justify="center" no-gutters class="fill-height" style="width: 100%;">
    <template v-if="terms">
      <v-col cols="12" lg="8" v-for="term in terms.data" :key="`term-${term.id}`">
        <v-card flat outlined>
          <v-card-title>
            {{ term.title }}
          </v-card-title>
          <v-card-subtitle>
            Versão: {{ term.version }} Data: {{ DateTime.fromSQL(term.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
          </v-card-subtitle>
          <v-card-text v-html="term.content"/>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="params.page" :length="terms.meta.last_page" :total-visible="7" @input="fetchTerms()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-terms',
    data: () => ({
      params: {
        page: 1,
        search: '',
        limit: 1
      },
      terms: null,
      DateTime: DateTime,
    }),
    async created() {
      await this.fetchTerms();
    },
    methods: {
      async fetchTerms() {
        const terms = await this.$axios.get(`/terms`, { params: this.params })
        this.terms = terms.data;
      },
    },
  };
</script>
