<template>
  <v-app>
    <!--Navigation-->
    <v-navigation-drawer v-if="authCheck" v-model="drawer" clipped fixed app>
      <template v-slot:prepend>
        <v-list-item two-line v-if="user">
          <v-list-item-avatar rounded>
            <v-img :src="user.avatar ? user.avatar.url : require('./assets/placeholder.png')"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider/>

      <v-list nav dense>
        <v-list-item :to="{ name: 'dashboard' }">
          <v-list-item-icon>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Estabelecimentos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'cart' }">
          <v-list-item-icon>
            <v-icon>mdi-cart</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Carrinho</v-list-item-title>
          </v-list-item-content>
          <v-chip x-small>{{ cartItemsCount }}</v-chip>
        </v-list-item>
        <v-list-item :to="{ name: 'orders' }">
          <v-list-item-icon>
            <v-icon>mdi-basket</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pedidos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'addresses' }">
          <v-list-item-icon>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Endereços</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
<!--        <v-list-item :to="{ name: 'cards' }">-->
<!--          <v-list-item-icon>-->
<!--            <v-icon>mdi-credit-card</v-icon>-->
<!--          </v-list-item-icon>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>Cartões</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->
        <v-list-item :to="{ name: 'coupons' }">
          <v-list-item-icon>
            <v-icon>mdi-ticket-percent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Cupons</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'profile' }">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'settings' }">
          <v-list-item-icon>
            <v-icon>mdi-wrench</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configuração</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-3">
          <v-btn block depressed @click="logout">Sair</v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!--App Bar-->
    <v-app-bar v-if="authCheck" dark app fixed clipped-left color="primary">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
      <v-toolbar-title class="headline text-uppercase">
        <router-link :to="{ name: 'dashboard' }">
          <span class="white--text">POINT</span>
          <span class="white--text font-weight-light">URBANO</span>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <!--Content-->
    <v-main :class="{ 'login-back': routesTheme.includes($route.name), 'chat': routesChatTheme.includes($route.name)}">
      <template v-if="authCheck && (!geolocationPermission || !notificationsPermission)">
        <v-banner dark color="warning" single-line icon="mdi-alert" app>
          Ative a geolocalização e as notificações.
        </v-banner>
      </template>
      <v-container class="fill-height" fluid>
        <router-view/>
      </v-container>
    </v-main>
    <!--Loading Dialog-->
    <v-overlay :value="loadingDialog" opacity="0">
      <v-card color="primary" class="pa-1" width="65">
        <v-img :src="require('./assets/loading.svg')" contain/>
      </v-card>
    </v-overlay>
    <!--Show Snackbar-->
    <v-snackbar v-model="showSnackbar" :timeout="10000" top centered dark color="primary">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showSnackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import resource from "@/resource";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import Echo from "laravel-echo"
import Pusher from 'pusher-js';
window.Pusher = Pusher;

export default {
  name: 'dashboard',
  data: () => ({
    routesTheme: ['login', 'register', 'reset_password'],
    routesChatTheme: ['message', 'qrcode'],
    geolocationPermission: true,
    notificationsPermission: true,
  }),
  watch: {
    themeDark(value) {
      this.$vuetify.theme.dark = value;
    },
    authCheck() {
      this.permissions();
      this.firebase();
      // this.echo();
    },
  },
  async created() {
    this.$vuetify.theme.dark = this.themeDark;
    if (this.authCheck) {
      await this.$store.dispatch('loginFetchAuthUser');
      this.permissions();
      this.firebase();
      // this.echo();
    }
  },
  methods: {
    echo() {
      if (!this.echoServer && this.authCheck) {
        this.echoServer = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_WEBSOCKET_KEY,
          wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
          wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
          wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
          forceTLS: false,
          encrypted: true,
          disableStats: true,
          enabledTransports: ['ws', 'wss'],
          authEndpoint: `${process.env.VUE_APP_HOST}/broadcasting/auth`,
          auth: { headers: { 'Authorization': `Bearer ${this.loginToken}` } }
        });
      }
    },
    firebase() {
      if (this.authCheck && !this.firebaseMessaging) {
        const app = initializeApp(this.firebaseConfig);
        this.firebaseMessaging = getMessaging(app);
        getToken(this.firebaseMessaging,{ vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY }).then((currentToken) => {
          resource.post(`/auth/user/message_token`, { token: currentToken });
        });
      }
    },
    permissions() {
      navigator.permissions.query({ name: 'geolocation' })
        .then((result) => {
          if (result.state === 'granted' || result.state === 'prompt') {
            this.geolocationPermission = true;
          } else if (result.state === 'denied') {
            this.geolocationPermission = false;
          }
        });
      navigator.permissions.query({ name: 'notifications' })
        .then((result) => {
          if (result.state === 'granted' || result.state === 'prompt') {
            this.notificationsPermission = true;
          } else if (result.state === 'denied') {
            this.notificationsPermission = false;
          }
        });
    },
    logout() {
      if (confirm('Tem certeza que deseja sair?')) {
        this.$store.dispatch('loginRemoveSession')
          .finally(() => {
            this.$store.dispatch('setDrawerStatus', null);
            this.$store.dispatch('setAddress', null);
            this.$store.dispatch('clearCartItems');
            this.$router.push({name: 'login'});
          });
      }
    }
  },
  computed: {
    appName() {
      return this.$store.getters.getAppName;
    },
    themeDark() {
      return this.$store.getters.getThemeDark;
    },
    firebaseMessaging: {
      get: function () {
        return this.$store.getters.getFirebaseMessaging;
      },
      set: function (status) {
        this.$store.dispatch('setFirebaseMessaging', status);
      },
    },
    echoServer: {
      get: function () {
        return this.$store.getters.getEchoServer;
      },
      set: function (status) {
        this.$store.dispatch('setEchoServer', status);
      },
    },
    drawer: {
      get: function () {
        return this.$store.getters.getDrawerStatus;
      },
      set: function (status) {
        this.$store.dispatch('setDrawerStatus', status);
      },
    },
    loadingDialog: {
      get: function () {
        return this.$store.getters.getLoadingDialog;
      },
      set: function (status) {
        this.$store.dispatch('setLoadingDialogStatus', status);
      },
    },
    showSnackbar: {
      get: function () {
        return this.$store.getters.getShowSnackbar;
      },
      set: function (status) {
        this.$store.dispatch('setShowSnackbarStatus', status);
      },
    },
    snackbarText() {
      return this.$store.getters.getSnackbarText;
    },
    user() {
      return this.$store.getters.getLoginAuthUser;
    },
    cartItemsCount() {
      return this.$store.getters.getCartItemsCount;
    },
    authCheck() {
      return this.$store.getters.getLoginAuthCheck;
    },
    firebaseConfig() {
      return this.$store.getters.getFirebaseConfig;
    },
    loginToken() {
      return this.$store.getters.getLoginToken;
    },
  },
}
</script>
