<template>
  <v-row dense align-content="start" class="fill-height">
    <template v-if="cartItemsCount">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Itens do Carrinho</h1>
      </v-col>
      <v-col cols="12" md="6" xl="4" v-for="orderItem in cartItems" :key="orderItem.id">
        <a-cart-item-tile :item="orderItem"/>
      </v-col>
      <v-col cols="12" v-if="cartBusiness">
        <v-btn depressed block large :to="{ name: 'business', params: { business: this.cartBusiness } }">Adicionar Itens</v-btn>
      </v-col>

      <v-col cols="12" class="pt-4 text-right" v-if="cartItemsCount > 0 && cartTotal >= cartMinOrderAmount">
        <v-btn text class="mr-1" @click="clearCart">Cancelar</v-btn>
        <v-btn depressed color="primary" @click="toCartService">Continuar</v-btn>
      </v-col>
      <v-col cols="12" v-else>
        <v-alert border="left" colored-border color="primary">
          <v-row dense align="center">
            <v-col class="shrink">
              <v-icon color="primary" size="36">icon-shopping-bag</v-icon>
            </v-col>
            <v-col class="grow">
              <span class="d-block">Mínimo em compra não atingido!</span>
              <span class="d-block text-caption">O mínimo em compras é de {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cartMinOrderAmount) }}.</span>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </template>
    <template v-else>
      <v-col cols="12">
        <v-alert border="left" colored-border color="primary">
          <v-row dense align="center">
            <v-col class="shrink">
              <v-icon color="primary" size="36">mdi-cart-off</v-icon>
            </v-col>
            <v-col class="grow">
              <span class="d-block">Não há produtos no seu carrinho!</span>
              <span class="d-block text-caption">Adicione alguns itens e aproveite.</span>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import ACartItemTile from "@/components/ACartItemTile";

export default {
  name: 'a-cart-resume',
  components: {
    ACartItemTile,
  },
  methods: {
    toCartService() {
      if (this.cartItemsCount === 0) {
        alert('Carrinho Vazio, Adicione um Produto!');
        return;
      }
      this.$router.push({ name: 'cart_service' });
    },
    clearCart() {
      this.$store.dispatch('clearCartItems');
    },
  },
  computed: {
    cartBusiness() {
      return this.$store.getters.getCartBusiness;
    },
    cartTotal() {
      return this.$store.getters.getCartTotal;
    },
    cartMinOrderAmount() {
      return this.$store.getters.getCartMinOrderAmount;
    },
    cartItems() {
      return this.$store.getters.getCartItems;
    },
    cartItemsCount() {
      return this.$store.getters.getCartItemsCount;
    },
  },
};
</script>
