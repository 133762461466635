<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Services-->
    <v-col cols="12">
      <v-item-group v-model="service" mandatory>
        <v-row dense>
          <v-col cols="12">
            <h1 class="text-subtitle-1 font-weight-light text-uppercase">Tipos de Serviço</h1>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-item v-slot="{ active, toggle }" value="DELIVERY">
              <v-card flat @click="toggle">
                <v-img :src="require('../../assets/banner_delivery.png')">
                  <v-icon dark color="#572E82" class="pa-1" v-if="active">mdi-checkbox-marked</v-icon>
<!--                  <v-icon dark color="#572E82" class="pa-1" v-else>mdi-checkbox-blank-outline</v-icon>-->
                </v-img>
              </v-card>
            </v-item>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-item v-slot="{ active, toggle }" value="PICK_UP">
              <v-card flat @click="toggle">
                <v-img :src="require('../../assets/banner_pickup.png')">
                  <v-icon dark color="#7C222C" class="pa-1" v-if="active">mdi-checkbox-marked</v-icon>
<!--                  <v-icon dark color="#7C222C" class="pa-1" v-else>mdi-checkbox-blank-outline</v-icon>-->
                </v-img>
              </v-card>
            </v-item>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <v-item v-slot="{ active, toggle }" value="DINE_IN">
              <v-card flat @click="toggle">
                <v-img :src="require('../../assets/banner_dinein.png')">
                  <v-icon dark color="#D87B03" class="pa-1" v-if="active">mdi-checkbox-marked</v-icon>
<!--                  <v-icon dark color="#D87B03" class="pa-1" v-else>mdi-checkbox-blank-outline</v-icon>-->
                </v-img>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-item-group>
      <div class="text-center text-uppercase grey--text mt-1" style="font-size: 10px;">
        Selecione o tipo de serviço que você deseja.
      </div>
    </v-col>
    <!--Addresses-->
    <template v-if="service === 'DELIVERY'">
      <template v-if="addresses && addresses.length">
        <v-col cols="12">
          <v-select v-model="address" :items="addresses" label="Endereço" item-text="address"
                    return-object item-value="id"/>
        </v-col>
        <v-col cols="12" class="pb-4" v-if="address">
          <v-btn block depressed color="primary" :to="{ name: 'dashboard' }">Ir Para Estabelecimentos</v-btn>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12">
          <v-alert v-bind="$attrs" v-if="service" dense border="left" colored-border color="primary">
            Para listar os restaurante próximo a você é preciso que vc forneca seu endereço e sua localização.
            <v-divider class="my-2"/>
            <v-btn block depressed :to="{ name: 'addresses_create' }">Cadastrar Novo Endereço</v-btn>
          </v-alert>
        </v-col>
      </template>
    </template>
    <template v-else>
      <v-col cols="12" class="py-4">
        <v-btn block depressed color="primary" :to="{ name: 'dashboard' }">Ir Para Estabelecimentos</v-btn>
      </v-col>
    </template>
  </v-row>
</template>

<script>
export default {
  name: 'a-service',
  data: () => ({
    addresses: null,
  }),
  async created() {
    const addresses = await this.$axios.get(`/users/${this.user.id}/addresses`);
    this.addresses = addresses.data.data;
  },
  watch: {
    service() {
      this.$store.dispatch('clearCartItems');
    },
  },
  computed: {
    address: {
      get: function () {
        return this.$store.getters.getAddress;
      },
      set: function (status) {
        this.$store.dispatch('setAddress', status);
      },
    },
    service: {
      get: function () {
        return this.$store.getters.getService;
      },
      set: function (status) {
        this.$store.dispatch('setService', status);
      },
    },
    user() {
      return this.$store.getters.getLoginAuthUser;
    }
  },
};
</script>
