<template>
  <v-row dense justify="center" class="fill-height">
    <!--Feed-->
    <template v-if="messages">
      <v-col cols="12" md="10" lg="8">
        <v-row dense>
          <template v-for="message in messages.data">
            <v-col cols="12" v-if="message.user" :key="`message-${message.id}`">
              <v-sheet flat rounded color="primary" dark class="float-right pa-1 text-pre-wrap">
                {{ message.text }}
                <div style="font-size: 10px; text-align: right; opacity: 0.5;">
                  {{ DateTime.fromSQL(message.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
                </div>
              </v-sheet>
            </v-col>
            <v-col cols="12" v-if="message.business" :key="`message-${message.id}`">
              <v-sheet flat rounded class="float-left pa-1 text-pre-wrap">
                {{ message.text }}
                <div style="font-size: 10px; text-align: right; opacity: 0.5;">
                  {{ DateTime.fromSQL(message.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
                </div>
              </v-sheet>
            </v-col>
          </template>
        </v-row>
        <div id="end-of-chat" class="text-center grey--text caption">Sem novas mensagens</div>
      </v-col>
    </template>
    <!--Input-->
    <v-col cols="12" md="10" lg="8" align-self="end" style="position: sticky; bottom: 8px;">
      <v-text-field v-model="payload.text" hide-details flat solo label="Digite uma mensagem"
                    @keyup.enter="sendMessage"/>
    </v-col>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-message',
    data: () => ({
      messages: null,
      polling: null,
      payload: {
        text: '',
      },
      DateTime: DateTime,
    }),
    async created() {
      await this.fetchMessages();
      this.pollData();
      // this.echo();
    },
    beforeDestroy () {
      clearInterval(this.polling);
    },
    methods: {
      pollData () {
        this.polling = setInterval(() => {
          this.fetchMessages();
        }, 30000);
      },
      echo() {
        if (this.echoServer && this.authCheck) {
          this.echoServer.private(`App.Models.User.${this.user.id}`)
            .notification((notification) => {
              if (notification.type === 'broadcast.order.message' && notification.order_id === parseInt(this.$route.params.order)) {
                this.fetchMessages();
                this.$vuetify.goTo('#end-of-chat');
              }
            });
        }
      },
      async fetchMessages () {
        const messages = await this.$axios.get(`/users/${this.user.id}/orders/${this.$route.params.order}/messages`, { params: { all: 1 }, loader: false })
        this.messages = messages.data;
      },
      async sendMessage () {
        await this.$axios.post(`/users/${this.user.id}/orders/${this.$route.params.order}/messages`, this.payload);
        this.payload.text = '';
        await this.fetchMessages();
        await this.$vuetify.goTo('#end-of-chat');
      },
    },
    computed: {
      user() {
        return this.$store.getters.getLoginAuthUser;
      },
      authCheck() {
        return this.$store.getters.getLoginAuthCheck;
      },
      loginToken() {
        return this.$store.getters.getLoginToken;
      },
      echoServer() {
        return this.$store.getters.getEchoServer;
      },
    },
  };
</script>
