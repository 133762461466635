import resource from "../../resource";
const tokenKeyName = 'PUB_CLIENT_TOKEN';
const userKeyName = 'PUB_CLIENT_USER';

const state = {
  token: null,
  user: null,
};

const getters = {
  getLoginToken(state) {
    if (localStorage.getItem(tokenKeyName)){
      state.token = localStorage.getItem(tokenKeyName);
    }
    return state.token;
  },
  getLoginAuthUser(state) {
    if (localStorage.getItem(userKeyName)){
      state.user = JSON.parse(localStorage.getItem(userKeyName));
    }
    return state.user;
  },
  getLoginAuthCheck(state) {
    return !!state.token || !!localStorage.getItem(tokenKeyName);
  },
};

const mutations = {
  SET_LOGIN_TOKEN(state, token) {
    state.token = token;
    localStorage.setItem(tokenKeyName, token);
  },
  REMOVE_LOGIN_TOKEN(state) {
    state.token = null;
    localStorage.removeItem(tokenKeyName);
  },
  SET_LOGIN_USER(state, user) {
    state.user = user;
    localStorage.setItem(userKeyName, JSON.stringify(user));
  },
  REMOVE_LOGIN_USER(state) {
    state.user = null;
    localStorage.removeItem(userKeyName);
  },
};

const actions = {
  loginCreateSession(context, credentials){
    return resource.post('/auth/user/login', credentials)
      .then((response) => {
        context.commit('SET_LOGIN_TOKEN', response.data.access_token);
        return context.dispatch('loginFetchAuthUser');
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveUser');
        return Promise.reject(error);
      });
  },
  loginRemoveSession(context){
    return resource.post('/auth/user/logout')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveUser');
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveUser');
        return Promise.reject(error);
      });
  },
  loginRemoveSessions(context){
    return resource.post('/auth/user/revoke')
      .then((response) => {
        context.dispatch('loginRemoveToken');
        context.dispatch('loginRemoveUser');
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  loginFetchAuthUser(context) {
    return resource.get('/auth/user/me')
      .then((response) => {
        context.dispatch('loginSetUser', response.data.data);
        return Promise.resolve(response);
      }).catch((error) => {
        context.dispatch('loginRemoveSession');
        return Promise.reject(error);
      });
  },
  loginSetToken(context, data) {
    context.commit('SET_LOGIN_TOKEN', data);
  },
  loginRemoveToken(context) {
    context.commit('REMOVE_LOGIN_TOKEN');
  },
  loginRemoveUser(context) {
    context.commit('REMOVE_LOGIN_USER');
  },
  loginSetUser(context, data) {
    context.commit('SET_LOGIN_USER', data);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
