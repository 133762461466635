<template>
  <v-card flat outlined v-bind="$attrs">
    <v-list-item two-line>
      <v-list-item-avatar rounded size="80">
        <v-img :src="business.avatar ? business.avatar.url : require('@/assets/placeholder.png')" aspect-ratio="1"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ business.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          Valor {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cartTotal) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="service === 'DELIVERY'">
          Entrega {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(business.delivery_tax) }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'a-cart-header-tile',
  props: {
    business: Object
  },
  computed: {
    cartTotal() {
      return this.$store.getters.getCartTotal;
    },
    service() {
      return this.$store.getters.getService;
    }
  }
}
</script>
