import AQrcode from './index'

export default [
  {
    path: '/qrcode',
    name: 'qrcode',
    component: AQrcode,
    meta: {
      requiresAuth: true,
    },
  },
];
