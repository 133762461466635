<template>
  <v-row dense align-content="start" class="fill-height">
    <template v-if="order">
      <!--Header-->
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>
            #{{ order.identification }}
          </v-card-title>
          <v-card-text v-if="order.status !== 'DELIVERED' && order.status !== 'CANCELLED'">
            <v-row dense>
              <v-col cols="4">
                <v-progress-linear v-if="order.status === 'PENDING'" indeterminate color="primary"/>
                <v-progress-linear v-else value="100" color="primary"/>
              </v-col>
              <v-col cols="4">
                <v-progress-linear v-if="order.status === 'PREPARING'" indeterminate color="primary"/>
                <v-progress-linear v-else-if="order.status === 'PENDING'" color="primary"/>
                <v-progress-linear v-else value="100" color="primary"/>
              </v-col>
              <v-col cols="4">
                <v-progress-linear v-if="order.status === 'DELIVERING'" indeterminate color="primary"/>
                <v-progress-linear v-else-if="order.status === 'PENDING' || order.status === 'PREPARING'" color="primary"/>
                <v-progress-linear v-else value="100" color="primary"/>
              </v-col>
              <v-col cols="12">
                <b v-if="order.status === 'PENDING'">Confirmando pedido</b>
                <b v-if="order.status === 'PREPARING'">Preparando pedido</b>
                <b v-if="order.status === 'DELIVERING'">Entregando pedido</b>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="order.status === 'PENDING'">
            <v-btn depressed block @click="cancelOrder">Cancelar Pedido</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!--Status-->
      <v-col cols="12" v-if="order.status === 'DELIVERED' || order.status === 'CANCELLED'">
        <template v-if="order.status === 'CANCELLED'">
          <v-alert dark color="red" border="left" class="mb-0" v-if="order.cancelled_by === 'BUSINESS'">
            O pedido foi cancelado pelo estabelecimento
          </v-alert>
          <v-alert dark color="red" border="left" class="mb-0" v-if="order.cancelled_by === 'USER'">
            O pedido foi cancelado pelo usuário
          </v-alert>
          <v-alert dark color="red" border="left" class="mb-0" v-if="order.cancelled_by === 'EXPIRED_TIME'">
            O pedido foi cancelado por tempo esgotado
          </v-alert>
        </template>
        <template v-if="order.status === 'DELIVERED'">
          <v-alert dark color="green" border="left" class="mb-0">
            O pedido foi entregue
          </v-alert>
        </template>
      </v-col>
      <!--Details-->
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-subtitle class="text-uppercase py-2">
            {{ order.business.name }}
          </v-card-subtitle>
          <v-card-text>
            <span class="d-block" v-if="order.user_order_requests > 1">Você ja efetuou <b>{{ order.user_order_requests }} pedidos</b> nessa loja</span>
            <span class="d-block" v-else>É o seu <b>primeiro</b> pedido nessa loja</span>
            <span class="d-block" v-if="order.document_fiscal_note">CPF na nota: <b>{{ cpfFormat(order.document_number) }}</b></span>
            <span class="d-block" v-if="order.delivery_prevision">Previsão: <b>{{ DateTime.fromSQL(order.delivery_prevision).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}</b></span>
          </v-card-text>

          <v-card-subtitle class="text-uppercase py-2">
            Dados do cliente
          </v-card-subtitle>
          <v-card-text>
            <template v-if="order.user">
              <span class="d-block">Nome: {{ order.user.name }}</span>
              <span class="d-block" v-if="order.user.telephone">Telefone: {{ order.user.telephone.telephone }}</span>
            </template>
            <span class="d-block" v-if="order.service === 'DINE_IN'">Mesa Nº <b>{{ order.totem.number }}</b></span>
            <span class="d-block" v-if="order.service === 'PICK_UP'">O atendente espera por <b>{{ order.pick_up_nickname }}</b></span>
            <template v-if="order.service === 'DELIVERY'">
              <span class="d-block">Endereço: {{ order.address.address }}</span>
              <span class="d-block">Bairro: {{ order.address.district }}</span>
              <span class="d-block">Comp: {{ order.address.complement }}</span>
              <span class="d-block">Ref: {{ order.address.reference }}</span>
              <span class="d-block">Cidade: {{ order.address.city.name }} - {{ order.address.city.state.uf }}</span>
              <span class="d-block">CEP: {{ order.address.cep }}</span>
            </template>
          </v-card-text>

          <v-card-subtitle class="text-uppercase py-2">
            Itens do pedido
          </v-card-subtitle>
          <v-card-text>
            <template v-for="orderItem in order.order_items">
              <span class="d-block" :key="`order-item-${orderItem.id}`">{{ orderItem.quantity }}x {{ orderItem.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderItem.total) }}</span>
              <template v-for="orderOption in orderItem.order_options">
                <span class="d-block pl-5" :key="`order-option-${orderOption.id}`">{{ orderOption.name }}</span>
                <template v-for="orderSelection in orderOption.order_selections">
                  <span class="d-block pl-10" :key="`order-selection-${orderSelection.id}`">{{ orderSelection.quantity }}x {{ orderSelection.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(orderSelection.amount) }}</span>
                </template>
              </template>
              <span class="d-block py-2" :key="`comment-${orderItem.id}`" v-if="orderItem.comments">
                Obs. <span class="text-uppercase">{{ orderItem.comments }}</span>
              </span>
            </template>
          </v-card-text>

          <template v-if="order.service === 'DELIVERY'">
            <v-card-subtitle class="text-uppercase py-2">
              Taxa de entrega
            </v-card-subtitle>
            <v-card-text>
              {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.delivery_tax) }}
            </v-card-text>
          </template>

          <template v-if="order.coupon">
            <v-card-subtitle class="text-uppercase py-2">
              Cupom de desconto
            </v-card-subtitle>
            <v-card-text v-if="order.coupon.type === 'DECIMAL'">
              {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.coupon.amount) }} sobre os itens
            </v-card-text>
            <v-card-text v-if="order.coupon.type === 'PERCENTAGE'">
              {{ order.coupon.percentage }}% sobre os itens
            </v-card-text>
            <v-card-text v-if="order.coupon.type === 'DELIVERY_FREE'">
              Frete grátis
            </v-card-text>
          </template>

          <v-card-subtitle class="text-uppercase py-2">
            Valor total do pedido
          </v-card-subtitle>
          <v-card-text>
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.total) }}
          </v-card-text>

          <v-card-subtitle class="text-uppercase py-2">
            Pagamento
          </v-card-subtitle>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" v-for="payment in order.payments" :key="`payment-${payment.id}`">
                <span class="d-block" v-if="payment.type === 'IN_APP'">Pago pelo APP, não há cobrança na entrega.</span>
                <span class="d-block" v-else>Pagamento com <b>{{ paymentType(payment.type) }}</b> (<b>{{ payment.payment_method.name }}</b>) na entrega.</span>
                <span class="d-block" v-if="payment.type === 'CASH' && payment.cash_change">Requer troco para {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(payment.cash_change) }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <!--Ratings-->
      <v-col cols-12 v-if="order.status === 'DELIVERED'">
        <v-card flat outlined>
          <v-card-subtitle class="text-uppercase py-2">
            Avaliação
          </v-card-subtitle>
          <v-card-text v-if="order.rating">
            <v-list-item two-line class="px-0">
              <v-list-item-content>
                <v-list-item-title>
                  <v-rating dense half-increments readonly background-color="gray lighten-1" v-model="order.rating.rating"/>
                </v-list-item-title>
                <v-list-item-subtitle v-if="order.rating.comment">
                  <v-icon small class="pb-1">mdi-account</v-icon> {{ order.rating.comment }}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="order.rating.replica">
                  <v-icon small class="pb-1">mdi-domain</v-icon> {{ order.rating.replica }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="destroyRating">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-card-text>
          <v-card-actions v-else>
            <v-btn depressed block :to="{ name: 'ratings_create', params: { order: order.id } }">Avaliar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!--Chat-->
      <template v-if="order.status !== 'PENDING' && order.status !== 'DELIVERED' && order.status !== 'CANCELLED'">
        <v-btn fixed bottom right fab dark color="primary" :to="{ name: 'message', params: { order: order.id } }">
          <v-icon>mdi-chat-processing</v-icon>
        </v-btn>
      </template>
    </template>
  </v-row>
</template>

<script>
import { DateTime } from "luxon";

  export default {
    name: 'a-order',
    data: () => ({
      order: null,
      polling: null,
      DateTime: DateTime,
    }),
    async created() {
      await this.fetchOrder();
      if (this.order.status !== 'DELIVERED' || this.order.status !== 'CANCELLED') {
        this.pollData();
      }
    },
    beforeDestroy () {
      clearInterval(this.polling);
    },
    methods: {
      pollData () {
        this.polling = setInterval(() => {
          this.fetchOrder();
        }, 60000);
      },
      async fetchOrder() {
        const order = await this.$axios.get(`/users/${this.user.id}/orders/${this.$route.params.order}`, { loader: false });
        this.order = order.data.data;
      },
      orderStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'PREPARING':
            return 'Preparando';
          case 'DELIVERING':
            return 'Saiu Para Entrega';
          case 'DELIVERED':
            return 'Entregue';
          case 'CANCELLED':
            return 'Cancelado';
          default:
            return 'Não definido';
        }
      },
      paymentStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Processando';
          case 'SUCCEEDED':
            return 'Aprovado';
          case 'FAILED':
            return 'Rejeitado';
          case 'CANCELLED':
            return 'Cancelado';
          case 'CASH_BACK':
            return 'Estornado';
          default:
            return 'Não definido';
        }
      },
      paymentType(type) {
        switch (type) {
          case 'IN_APP':
            return 'No APP';
          case 'CREDIT_CARD':
            return 'Cartão de Crédito';
          case 'CURRENCY':
            return 'Dinheiro';
          case 'DEBIT_CARD':
            return 'Cartão de Debito';
          case 'MEAL_TICKET':
            return 'Vale Refeição';
          default:
            return 'Não definido';
        }
      },
      cpfFormat(date) {
        return `${date.slice(0, 3)}.${date.slice(3, 6)}.${date.slice(6, 9)}-${date.slice(9, 11)}`;
      },
      async cancelOrder() {
        if (confirm(`Tem certeza que deseja cancelar o pedido #${this.order.identification}?`)) {
          const order = await this.$axios.put(`/users/${this.user.id}/orders/${this.order.id}`, { status: 'CANCELLED' });
          this.order = order.data.data;
          clearInterval(this.polling);
        }
      },
      async destroyRating() {
        if (!this.order.rating) {
          alert('Não há avaliações para esse pedido!');
          return;
        }
        if (confirm('Tem certeza que deseja excluir?')) {
          await this.$axios.delete(`/users/${this.user.id}/orders/${this.order.id}/ratings/${this.order.rating.id}`);
          await this.fetchOrder();
        }
      }
    },
    computed: {
      user() {
        return this.$store.getters.getLoginAuthUser;
      }
    },
  };
</script>
