import ABusiness from './index'

export default [
  {
    path: '/businesses/:business',
    name: 'business',
    component: ABusiness,
    meta: {
      requiresAuth: true,
    },
  },
];
