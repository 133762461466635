import AApplication from './index'

export default [
  {
    path: '/application',
    name: 'application',
    component: AApplication,
    meta: {
      requiresAuth: true,
    },
  }
];
