import AInfo from './index'

export default [
  {
    path: '/businesses/:business/info',
    name: 'info',
    component: AInfo,
    meta: {
      requiresAuth: true,
    },
  },
];
