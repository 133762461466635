<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Header-->
    <v-col cols="12" sm="12" md="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Pedidos</h1>
    </v-col>
    <!--Orders-->
    <template v-if="orders">
      <v-col cols="12" md="6" v-for="order in orders.data" :key="order.id">
        <v-card flat outlined :to="{name: 'order', params: { order: order.id }}">
          <v-list-item two-line>
            <v-list-item-avatar rounded :size="80">
              <v-img :src="order.business.avatar ? order.business.avatar.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>#{{ order.identification }} - <span :class="orderStatusColor(order.status)">{{ orderStatus(order.status) }}</span></v-list-item-title>
              <v-list-item-subtitle>
                {{ order.business.name }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                {{ DateTime.fromSQL(order.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Valor: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(order.total) }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-orders',
    data: () => ({
      orders: null,
      DateTime: DateTime,
    }),
    async created() {
      const orders = await this.$axios.get(`/users/${this.user.id}/orders`, { params: { all: 1 } });
      this.orders = orders.data;
    },
    methods: {
      orderStatusColor(status) {
        switch (status) {
          case 'PENDING':
            return 'amber--text darken-4';
          case 'PREPARING':
            return 'blue--text darken-4';
          case 'DELIVERING':
            return 'purple--text darken-4';
          case 'DELIVERED':
            return 'green--text darken-4';
          case 'CANCELLED':
            return 'red--text darken-4';
          default:
            return 'grey--text darken-4';
        }
      },
      orderStatus(status) {
        switch (status) {
          case 'PENDING':
            return 'Pendente';
          case 'PREPARING':
            return 'Preparando';
          case 'DELIVERING':
            return 'Saiu Para Entrega';
          case 'DELIVERED':
            return 'Entregue';
          case 'CANCELLED':
            return 'Cancelado';
          default:
            return 'Não definido';
        }
      },
    },
    computed: {
      user() {
        return this.$store.getters.getLoginAuthUser;
      }
    },
  };
</script>
