import ARatingForm from './form'

export default [
  {
    path: '/orders/:order/ratings/create',
    component: ARatingForm,
    name: 'ratings_create',
    meta: {
      requiresAuth: true
    }
  },
];
