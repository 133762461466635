<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Header-->
    <template v-if="item">
      <v-col cols="12">
        <v-card flat outlined>
          <a-item-tile :item="item"/>
        </v-card>
      </v-col>
    </template>
    <!--Items-->
    <template v-if="item && mount">
      <template v-for="(option, index) in item.options">
        <v-col cols="12" :key="option.id" v-if="option.active">
          <template v-if="option.min === 1 && option.max === 1">
            <a-option-single v-model="mount.options[index]" @complete="$set(complete, index, $event)" :option="option"/>
          </template>
          <template v-else-if="option.min !== 1 && option.max !== 1 && option.twice === true">
            <a-option-increment v-model="mount.options[index]" @complete="$set(complete, index, $event)" :option="option"/>
          </template>
          <template v-else>
            <a-option-multiple v-model="mount.options[index]" @complete="$set(complete, index, $event)" :option="option"/>
          </template>
        </v-col>
      </template>
    </template>
    <!--Comments-->
    <template v-if="item && mount">
      <v-col cols="12">
        <v-textarea auto-grow rows="1" v-model="mount.comments" label="Observações" persistent-hint
                    :disabled="!isOptionsMinMaxValid" hint="*Complete as opções acima antes de inserir uma observação."/>
      </v-col>
    </template>
    <!--Btn Add-->
    <template v-if="item && mount && business">
      <template v-if="business.online">
        <v-col cols="12">
          <v-row dense>
            <v-col class="shrink">
              <a-counter v-model="mount.quantity" :initial-count="mount.quantity" :minimal-count="1"/>
            </v-col>
            <v-col class="grow">
              <v-btn depressed :disabled="!isOptionsMinMaxValid" block color="primary" @click="setCart">
                Adicionar {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itemValue(mount)) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="12">
          <v-alert border="left" colored-border color="primary">
            <v-row dense align="center">
              <v-col class="shrink">
                <v-icon color="primary" size="36">mdi-lock</v-icon>
              </v-col>
              <v-col class="grow">
                <span class="d-block">Estabelecimento Fechado!</span>
                <span class="d-block text-caption">Não é possível realizar pedidos no momento!</span>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
import AItemTile from "@/components/AItemTile";
import AOptionSingle from "@/components/AOptionSingle";
import AOptionIncrement from "@/components/AOptionIncrement";
import AOptionMultiple from "@/components/AOptionMultiple";
import ACounter from "@/components/ACounter";

export default {
  name: 'a-mount',
  components: {
    ACounter,
    AOptionMultiple,
    AOptionIncrement,
    AOptionSingle,
    AItemTile,
  },
  data: () => ({
    business: null,
    item: null,
    mount: null,
    complete: [],
  }),
  created() {
    this.$axios.get(`/businesses/${this.$route.params.business}`)
      .then((response) => {
        this.business = response.data.data;
      });
    this.$axios.get(`/businesses/${this.$route.params.business}/items/${this.$route.params.item}`)
      .then((response) => {
        this.item = response.data.data;
        this.mount = {
          quantity: 1,
          name: response.data.data.name,
          thumbnail: response.data.data.thumbnail,
          item_id: response.data.data.id,
          amount: response.data.data.amount,
          promotion: response.data.data.promotion,
          promotion_amount: response.data.data.promotion_amount,
          options: [],
        }
      });
  },
  methods: {
    itemValue(item) {
      let itemPrice = item.promotion ? item.promotion_amount : item.amount;
      let optionsSum = item.options.reduce(function (optionsCarry, optionCurrent) {
        let selectionsSum = optionCurrent.selections.reduce(function (selectionCarry, itemOptionCurrent) {
          return selectionCarry + itemOptionCurrent.amount;
        }, 0);
        return optionsCarry + selectionsSum;
      }, itemPrice);
      return optionsSum * item.quantity;
    },
    setCart() {
      this.$store.dispatch('setCartItem', {
        item: Object.assign({}, this.mount),
        business: this.$route.params.business,
      }).then(() => {
        this.$router.replace({ name: 'cart'});
      });
    },
  },
  computed: {
    isOptionsMinMaxValid() {
      return this.complete.every(item => item);
    },
  },
};
</script>
