import ACoupons from './index'

export default [
  {
    path: '/businesses/:business/coupons',
    name: 'business.coupons',
    component: ACoupons,
    meta: {
      requiresAuth: true
    }
  },
];
