<template>
  <v-input :value="value" v-bind="$attrs">
    <v-responsive :aspect-ratio="$vuetify.breakpoint.xsOnly ? 1 : 21/9">
      <div class="leaflet-map" id="map-point"/>
    </v-responsive>
  </v-input>
</template>

<script>
  import * as L from 'leaflet';
  import { GestureHandling } from 'leaflet-gesture-handling';

  import 'leaflet-fullscreen';

  import 'leaflet/dist/leaflet.css';
  import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
  import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

  export default {
    inheritAttrs: false,
    name: 'a-leaflet-point',
    props: ['value'],
    data: () => ({
      map: null,
      marker: null,
      style: {
        color: '#D32F2F',
        weight: 2,
        opacity: 0.65
      },
      myIcon: L.icon({
        iconUrl: require('../assets/marker-icon.png'),
        iconRetinaUrl: require('../assets/marker-icon-2x.png'),
        shadowUrl: require('../assets/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      })
    }),
    mounted() {
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

      L.Marker.prototype.options.icon = this.myIcon;

      this.map = L.map('map-point', {
        gestureHandling: true,
        fullscreenControl: true
      });

      if (this.value) {
        this.marker = L.geoJSON(this.value).addTo(this.map);
        this.map.fitBounds(this.marker.getBounds());
      } else {
        this.changePosition();
      }

      this.map.on('click', (e) => {
        if (this.marker) {
          this.map.removeLayer(this.marker);
        }
        this.marker = L.marker(e.latlng).addTo(this.map);
        this.$emit('input', this.marker.toGeoJSON().geometry);
      });

      L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: '&copy; <a href="https://www.google.com">Google</a>'
      }).addTo(this.map);

      // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   maxZoom: 18,
      //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      // }).addTo(this.map);
    },
    watch: {
      value(value) {
        if (this.marker) {
          this.map.removeLayer(this.marker);
        }
        this.marker = L.geoJSON(value).addTo(this.map);
        this.map.fitBounds(this.marker.getBounds());
      },
    },
    methods: {
      changePosition() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.map.setView(L.latLng(position.coords.latitude, position.coords.longitude), 18);
          }, () => {
            this.map.setView(L.latLng(-13.882244132383343, -53.92089843750001), 5);
          });
        } else {
          this.map.setView(L.latLng(-13.882244132383343, -53.92089843750001), 5);
        }
      },
    },
  };
</script>
