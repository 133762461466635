import AItems from './index'

export default [
  {
    path: '/businesses/:business/groups/:group/items',
    name: 'items',
    component: AItems,
    meta: {
      requiresAuth: true
    }
  },
];
