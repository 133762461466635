<template>
  <v-row dense align-content="start" class="fill-height" v-if="paymentMethodsSplit">
<!--    <v-col cols="12">-->
<!--      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Pagar Agora</h1>-->
<!--    </v-col>-->
<!--    <v-col cols="12">-->
<!--      <v-item-group v-model="cartPaymentType">-->
<!--        <v-row dense>-->
<!--          <v-item value="IN_APP" v-slot:default="{ active, toggle }">-->
<!--            <v-col cols="12" md="3" lg="2" xl="1">-->
<!--              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">-->
<!--                <v-list-item two-line>-->
<!--                  <v-list-item-avatar>-->
<!--                    <v-icon>mdi-credit-card</v-icon>-->
<!--                  </v-list-item-avatar>-->
<!--                  <v-list-item-content>-->
<!--                    <v-list-item-title>Pagamento pelo App</v-list-item-title>-->
<!--                    <v-list-item-subtitle>Pagamento no cartão</v-list-item-subtitle>-->
<!--                  </v-list-item-content>-->
<!--                </v-list-item>-->
<!--              </v-card>-->
<!--            </v-col>-->
<!--          </v-item>-->
<!--        </v-row>-->
<!--      </v-item-group>-->
<!--    </v-col>-->

    <v-col cols="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Pagar na Entrega</h1>
    </v-col>
    <v-col cols="12">
      <v-item-group v-model="cartPaymentType">
        <v-row dense>
          <v-item value="CREDIT_CARD" v-slot:default="{ active, toggle }" v-if="paymentMethodsCreditCard">
            <v-col cols="12" md="3" lg="2" xl="1">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-credit-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Cartão de Credito</v-list-item-title>
                    <v-list-item-subtitle>Pagamento no cartão</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-item>
          <v-item value="DEBIT_CARD" v-slot:default="{ active, toggle }" v-if="paymentMethodsDebitCard">
            <v-col cols="12" md="3" lg="2" xl="1">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-credit-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Cartão de Débito</v-list-item-title>
                    <v-list-item-subtitle>Pagamento no cartão</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-item>
          <v-item value="MEAL_TICKET" v-slot:default="{ active, toggle }" v-if="paymentMethodsMealTicket">
            <v-col cols="12" md="3" lg="2" xl="1">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-credit-card</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Vale Refeição</v-list-item-title>
                    <v-list-item-subtitle>Pagamento no vale</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-item>
          <v-item value="CURRENCY" v-slot:default="{ active, toggle }" v-if="paymentMethodsCurrency">
            <v-col cols="12" md="3" lg="2" xl="1">
              <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                <v-list-item two-line>
                  <v-list-item-avatar>
                    <v-icon>mdi-cash</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>Dinheiro</v-list-item-title>
                    <v-list-item-subtitle>Pagamento no dinheiro</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-item>
        </v-row>
      </v-item-group>
    </v-col>

    <v-col cols="12" class="pt-4 text-right">
      <v-btn text class="mr-1" @click="$router.back()">Voltar</v-btn>
      <v-btn depressed color="primary" @click="toCartPaymentMethod">Continuar</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-cart-payment-type',
  data: () => ({
    paymentMethodsCreditCard: null,
    paymentMethodsDebitCard: null,
    paymentMethodsMealTicket: null,
    paymentMethodsCurrency: null,
  }),
  async created() {
    const paymentMethods = await this.$axios.get(`/businesses/${this.cartBusiness}/payment_methods`, { params: { all: 1 } });
    this.paymentMethodsCreditCard = paymentMethods.data.data.some(paymentMethod => paymentMethod.type === 'CREDIT_CARD')
    this.paymentMethodsDebitCard = paymentMethods.data.data.some(paymentMethod => paymentMethod.type === 'DEBIT_CARD')
    this.paymentMethodsMealTicket = paymentMethods.data.data.some(paymentMethod => paymentMethod.type === 'MEAL_TICKET')
    this.paymentMethodsCurrency = paymentMethods.data.data.some(paymentMethod => paymentMethod.type === 'CURRENCY')
  },
  methods: {
    toCartPaymentMethod() {
      if(!this.cartPaymentType){
        alert('Selecione um Meio de Pagamento!');
        return;
      }
      this.$router.push({ name: 'cart_payment_method' });
    },
  },
  computed: {
    cartBusiness() {
      return this.$store.getters.getCartBusiness;
    },
    cartPaymentType: {
      get: function () {
        return this.$store.getters.getCartPaymentType;
      },
      set: function (status) {
        this.$store.dispatch('setCartPaymentType', status);
      },
    },
  },
};
</script>
