import resource from '../../resource';

const state = {
  coupons: null,
};

const getters = {
  getCoupons(state) {
    return state.coupons;
  },
};

const mutations = {
  SET_COUPONS(state, data) {
    state.coupons = data;
  },
};

const actions = {
  fetchCoupons(context, { user }) {
    return resource.get(`/users/${user}/coupons`, { params: { valid: 1 } })
      .then((response) => {
        context.commit('SET_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  fetchCoupon(context, { user, coupon }) {
    return resource.get(`/users/${user}/coupons/${coupon}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  searchCoupons(context, { user, search }) {
    return resource.get(`/users/${user}/coupons`, { params: { name: search, valid: 1 } })
      .then((response) => {
        context.commit('SET_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  toCouponsPage(context, { user, search, page }) {
    return resource.get(`/users/${user}/coupons`, { params: { name: search, page: page, valid: 1 } })
      .then((response) => {
        context.commit('SET_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  createCoupon(context, { user, payload }) {
    return resource.post(`/users/${user}/coupons`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  updateCoupon(context, { user, coupon, payload }) {
    return resource.put(`/users/${user}/coupons/${coupon}`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
  deleteCoupon(context, { user, coupon }) {
    return resource.delete(`/users/${user}/coupons/${coupon}`)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
