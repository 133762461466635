import resource from "@/resource";

const state = {
  business: null,
  coupons: null,
};

const getters = {
  getCouponBusiness(state) {
    return state.business;
  },
  getCouponCoupons(state) {
    return state.coupons;
  },
};

const mutations = {
  SET_COUPON_BUSINESS(state, data) {
    state.business = data;
  },
  SET_COUPON_COUPONS(state, data) {
    state.coupons = data;
  },
};

const actions = {
  fetchCouponBusiness(context, { business }) {
    return resource.get(`/businesses/${business}`)
      .then((response) => {
        context.commit('SET_COUPON_BUSINESS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  fetchCouponCoupons(context, { business }) {
    return resource.get(`/businesses/${business}/coupons`, { params: { all: 1, valid: 1, order_by: 'id', direction: 'asc' } })
      .then((response) => {
        context.commit('SET_COUPON_COUPONS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  // redeemCouponCoupon(context, { user }) {
  //   return resource.get(`/user/${user}/coupons`, { params: { all: 1, valid: 1, order_by: 'id', direction: 'asc' } })
  //     .then((response) => {
  //       context.commit('SET_COUPON_COUPONS', response.data);
  //       return Promise.resolve(response);
  //     }).catch(function (error) {
  //       return Promise.reject(error);
  //     });
  // },
  redeemCouponCoupon(context, { user, payload }) {
    return resource.post(`/users/${user}/coupons`, payload)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
