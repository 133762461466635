import Vue from 'vue';
import Vuex from 'vuex';

import application from "../views/Application/store.module";
import card from "../views/Card/store.module";
import cart from "../views/Cart/store.module";
import coupon from "../views/Coupon/store.module";
import coupons from "../views/Coupons/store.module";
import httpNotFound from "../views/HttpNotFound/store.module";
import login from "../views/Login/store.module";
import mount from "../views/Mount/store.module";
import profile from "../views/Profile/store.module";
import register from "../views/Register/store.module";
import resetPassword from "../views/ResetPassword/store.module";
import settings from "../views/Settings/store.module";
import thirdPartyLogin from "../views/ThirdPartyLogin/store.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    application,
    card,
    cart,
    coupon,
    coupons,
    httpNotFound,
    login,
    mount,
    profile,
    register,
    resetPassword,
    settings,
    thirdPartyLogin,
  },
})
