import resource from "../../resource";

const state = {
  business: null,
  item: null,
};

const getters = {
  getMountBusiness(state) {
    return state.business;
  },
  getMountBusinessItem(state) {
    return state.item;
  },
};

const mutations = {
  SET_MOUNT_BUSINESS(state, status) {
    state.business = status;
  },
  SET_MOUNT_BUSINESS_ITEM(state, status) {
    status.data.options = status.data.options.filter(item => item.active)
    state.item = status;
  },
};

const actions = {
  fetchMountBusiness(context, { business }) {
    return resource.get(`/businesses/${business}`)
      .then((response) => {
        context.commit('SET_MOUNT_BUSINESS', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
  fetchMountBusinessItem(context, { business, item }) {
    return resource.get(`/businesses/${business}/items/${item}`)
      .then((response) => {
        context.commit('SET_MOUNT_BUSINESS_ITEM', response.data);
        return Promise.resolve(response);
      }).catch(function (error) {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
