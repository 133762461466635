<template>
  <v-sheet dark rounded color="primary" class="pa-2 d-flex align-center justify-space-between" height="36" width="100">
    <v-btn :disabled="disabled" icon x-small @click="decrease">
      <v-icon>mdi-minus</v-icon>
    </v-btn>
    <span class="pl-2 pr-2">{{ count }}</span>
    <v-btn :disabled="disabled" icon x-small @click="increase">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-sheet>
</template>

<script>
  export default {
    name: 'a-counter',
    props: {
      value: null,
      initialCount: {
        type: Number,
        default: 0,
      },
      minimalCount: {
        type: Number,
        default: 0,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        radio: '',
        count: 0,
      }
    },
    mounted() {
      this.index();
    },
    watch: {
      count: {
        handler () {
          this.$emit('input', this.count);
        },
        deep: true,
      },
    },
    methods: {
      index (){
        this.count = this.initialCount;
      },
      increase (){
        this.count++;
        this.$emit('increase');
      },
      decrease (){
        this.count = (this.count <= this.minimalCount) ? this.minimalCount : this.count - 1;
        this.$emit('decrease');
      },
    },
  }
</script>

<style scoped>
  .counter{
    display: inline-block;
    border-radius: 5px;
    border-color: red;
    border-style: solid;
    border-width: 2px;
    box-sizing: content-box;
  }
</style>
