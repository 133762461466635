<template>
  <v-responsive v-bind="$attrs">
    <div class="leaflet-map" id="map-business"></div>
  </v-responsive>
</template>

<script>
  import * as L from 'leaflet';
  import { GestureHandling } from 'leaflet-gesture-handling';

  import 'leaflet-fullscreen';

  import 'leaflet/dist/leaflet.css';
  import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
  import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

  export default {
    inheritAttrs: false,
    name: 'a-leaflet-map-positions',
    data: () => ({
      map: null,
      markers: [],
      tiles: null,
      style: {
        color: '#D32F2F',
        weight: 2,
        opacity: 0.65
      },
      myIcon: L.icon({
        iconUrl: require('../assets/marker-icon.png'),
        iconRetinaUrl: require('../assets/marker-icon-2x.png'),
        shadowUrl: require('../assets/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      })
    }),
    mounted() {
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

      L.Marker.prototype.options.icon = this.myIcon;

      this.map = L.map('map-business', {
        gestureHandling: false,
        fullscreenControl: true
      });

      this.tiles = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        minZoom: 15,
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: '&copy; <a href="https://www.google.com">Google</a>',
      }).addTo(this.map);

      // this.tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   minZoom: 15,
      //   maxZoom: 18,
      //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      // }).addTo(this.map);

      this.changePosition();

      this.map.on('moveend', () => {
        const area = L.rectangle(this.map.getBounds()).toGeoJSON().geometry;
        this.getBusinesses(area);
      });

    },
    methods: {
      async getBusinesses(area) {
        const businesses = await this.$axios.get(`/businesses`, { params: {area: area, active: 1, subscribed: 1 }, loader: false });

        this.markers.forEach(marker => this.map.removeLayer(marker));
        this.markers = [];

        businesses.data.data.forEach(item => {
          const avatar = item.avatar ? item.avatar.url : require('../assets/placeholder.png');

          const styledIcon = new L.DivIcon({
            className: 'my-div-icon',
            html: `<a href="/businesses/${item.id}"><img class="my-div-image" src="${avatar}"/></a><span class="my-div-span">${item.name}</span>`
          })

          const styledMarker = L.geoJson(item.location, { pointToLayer: (feature, latlng) => {
            return L.marker(latlng, { icon: styledIcon });
          }}).addTo(this.map);

          this.markers.push(styledMarker);
        })
      },
      changePosition() {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            this.map.setView(L.latLng(position.coords.latitude, position.coords.longitude), 18);
          }, () => {
            this.map.setView(L.latLng(-13.882244132383343, -53.92089843750001), 5);
          });
        } else {
          this.map.setView(L.latLng(-13.882244132383343, -53.92089843750001), 5);
        }
      },
    }
  }
</script>

<style>
.my-div-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.my-div-icon a {
  /*margin: 0 !important;*/
  width: 40px;
  height: 40px;
}

.my-div-icon .my-div-image {
  width: 40px;
  height: 40px;
  border: #FFFFFF 4px solid;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.my-div-icon .my-div-span {
  white-space: nowrap;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  color: #000000;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  text-decoration: none;
  border-radius: 5px;
  padding: 2px 6px;
  margin-top: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>