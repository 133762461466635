<template>
  <v-card flat outlined v-if="optionModel && selectionsModel">
    <v-card-text>
      <v-row dense no-gutters align="center">
        <v-col class="grow">
          <h1 class="text-body-1 text-uppercase">{{ option.name }}</h1>
          <h1 class="text-body-2 font-weight-light text-uppercase" v-if="option.min > 0">Item Obrigatório, mínimo {{ option.min }}</h1>
          <h1 class="text-body-2 font-weight-light text-uppercase" v-else>Item Não Obrigatório</h1>
          <h1 class="text-body-2 font-weight-light text-uppercase">{{ getSelectionsQuantity() }} de {{ option.max }}</h1>
        </v-col>
        <v-col class="shrink">
          <template v-if="option.min > 0">
            <v-icon v-if="isOptionMinMaxValid()" color="green">mdi-check-circle-outline</v-icon>
            <v-icon v-else color="red">mdi-circle-outline</v-icon>
          </template>
          <template v-else>
            <v-icon color="green">mdi-check-circle-outline</v-icon>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row dense>
        <template v-for="(selection, index) in selectionsModel">
          <v-col cols="12" :key="selection.id" v-if="selection.active">
            <v-divider class="mb-4" v-if="index"/>
            <v-row dense @click="selectOne(selection)" class="pb-2">
              <v-col class="grow" align-self="center">
                <div>{{ selection.name }}</div>
                <div class="caption" v-if="selection.description">{{ selection.description }}</div>
                <div v-if="selection.amount > 0">+ {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selection.amount) }}</div>
              </v-col>
              <v-col class="shrink" align-self="center">
                <v-icon>
                  {{ isSelected(selection) ? 'mdi-radiobox-marked' : 'mdi-radiobox-blank' }}
                </v-icon>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'a-option-single',
    props: {
      value: Object,
      option: Object,
      complete: Boolean,
    },
    data: () => ({
      optionModel: null,
      selectionsModel: null,
    }),
    mounted() {
      this.optionModel = {
        option_id: this.option.id,
        name: this.option.name,
        min: this.option.min,
        max: this.option.max,
        selections: []
      };
      this.selectionsModel = this.option.selections;
    },
    watch: {
      optionModel: {
        handler: function() {
          this.$emit('complete', this.isOptionMinMaxValid());
          this.$emit('input', this.optionModel);
        },
        deep: true,
      },
    },
    methods: {
      addSelection(selection, quantity) {
        this.optionModel.selections.push({
          selection_id: selection.id,
          name: selection.name,
          quantity: quantity,
          amount: selection.amount,
        });
      },
      selectOne(selection) {
        this.emptySelections();
        this.addSelection(selection, 1);
      },
      getSelectionsQuantity() {
        return this.optionModel.selections.reduce((selectionAcumulador, itemOptionAtual) => {
          return selectionAcumulador + itemOptionAtual.quantity;
        }, 0);
      },
      isSelected(selection) {
        return this.optionModel.selections.some((item) => {
          return item.selection_id === selection.id
        });
      },
      getOptionMin() {
        return this.option.min;
      },
      getOptionMax() {
        return this.option.max;
      },
      isOptionMinValid() {
        return this.getSelectionsQuantity() >= this.getOptionMin();
      },
      isOptionMaxValid() {
        return this.getSelectionsQuantity() <= this.getOptionMax();
      },
      isOptionMinMaxValid() {
        return this.isOptionMinValid() && this.isOptionMaxValid();
      },
      emptySelections() {
        this.optionModel.selections = [];
      }
    }
  }
</script>
