<template>
  <v-responsive v-bind="$attrs">
    <div class="leaflet-map" id="map-business"></div>
  </v-responsive>
</template>

<script>
  import * as L from 'leaflet';
  import { GestureHandling } from 'leaflet-gesture-handling';

  import 'leaflet/dist/leaflet.css';
  import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

  export default {
    inheritAttrs: false,
    name: 'a-leaflet-map-business',
    props: {
      business: Object,
    },
    data: () => ({
      map: null,
      polygon: null,
      marker: null,
      tiles: null,
      style: {
        color: '#D32F2F',
        weight: 2,
        opacity: 0.65
      },
      myIcon: L.icon({
        iconUrl: require('../assets/marker-icon.png'),
        iconRetinaUrl: require('../assets/marker-icon-2x.png'),
        shadowUrl: require('../assets/marker-shadow.png'),
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28],
        shadowSize: [41, 41]
      })
    }),
    mounted() {
      L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

      L.Marker.prototype.options.icon = this.myIcon;

      this.map = L.map('map-business', {
        zoomControl: false,
        gestureHandling: true
      });

      this.tiles = L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3'],
        attribution: '&copy; <a href="https://www.google.com">Google</a>',
      }).addTo(this.map);

      // this.tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //   maxZoom: 18,
      //   attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      // }).addTo(this.map);

      if (this.business) {
        this.setLayers(this.business);
      }
    },
    watch: {
      business(value) {
        this.removeLayers();
        this.setLayers(value);
      },
    },
    methods: {
      setLayers(business){
        if (business.location) {
          this.marker = L.geoJSON(business.location).addTo(this.map);
          this.map.fitBounds(this.marker.getBounds());
        }

        if (business.enable_delivery) {
          this.polygon = L.geoJSON(business.delivery_area, this.style).addTo(this.map);
          this.map.fitBounds(this.polygon.getBounds());
        }
      },
      removeLayers(){
        if (this.marker) {
          this.map.removeLayer(this.marker);
        }

        if (this.polygon) {
          this.map.removeLayer(this.polygon);
        }
      }
    }
  }
</script>
