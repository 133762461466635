<template>
  <v-row dense v-touch="{ down: () => pullToRefresh() }" align-content="start" class="fill-height" id="teste">
    <!--Addresses-->
    <v-col cols="12">
      <v-card flat outlined>
        <v-list-item two-line :to="{ name: 'service' }">
          <v-list-item-content>
            <v-list-item-title v-if="service === 'PICK_UP' || service === 'DINE_IN'">
              Sua Localização
            </v-list-item-title>
            <v-list-item-title v-if="service === 'DELIVERY'">
              {{ address ? address.address : 'Selecione Um Endereço' }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ serviceName(service) }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon :to="{ name: 'qrcode' }">
              <v-icon color="primary">mdi-qrcode-scan</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <!--Slides-->
    <v-col cols="12" v-if="spots">
      <v-slide-group show-arrows="desktop">
        <v-slide-item v-for="spot in spots.data" :key="`spot-${spot.id}`">
          <v-card flat class="mx-1" width="300" @click="fetchBusinesses(spot.params)">
            <v-img :src="spot.banner ? spot.banner.url :require('../../assets/placeholder.png')" :aspect-ratio="900/420"/>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-col>
    <!--Categories-->
    <template v-if="categories">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Categorias</h1>
      </v-col>
      <v-col cols="12">
        <v-slide-group show-arrows="desktop">
          <v-slide-item v-for="category in categories.data" :key="`category-${category.id}`">
            <v-card flat class="mx-1" width="100" @click="fetchBusinesses({ category: category.id })">
              <v-img class="align-end" :src="category.banner ? category.banner.url :require('../../assets/placeholder.png')" :aspect-ratio="1">
                <svg class="d-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 159">
                  <path :fill="category.color" d="M1000,92v67H0v-6a44.29,44.29,0,0,0,4.43.44q67.25.13,134.49.2c11.19,0,14.46-4.25,11.43-15.25h4.47q184.24.14,368.47.26,140.49.09,281,.11c1.83,0,4.06.37,5.38-.52,2-1.33,4.63-3.66,4.56-5.47-.09-2.15-2.38-4.82-4.43-6.09-1.74-1.07-4.52-.62-6.83-.62q-203-.06-406-.08-132.5,0-265-.08-66,0-132,.1V91c1.5-.07,3-.21,4.49-.21l305.85.34,431.79.53Q871.06,91.82,1000,92Z"/>
                  <path :fill="category.color" d="M322,0c2.41.39,4.81,1.11,7.22,1.12q120.21.09,240.43.07h3c3.35,0,7.35-.49,7.41,4.37s-4,4.37-7.33,4.37l-230.44,0h-6.92c.33,1.64.51,2.86.83,4,3.74,13.47-4.77,25.62-18.79,26.09-12.15.41-24.32.08-36.49.08L0,40V0Z"/>
                  <path :fill="category.color" d="M1000,72c-11.65-.1-23.31-.3-35-.31q-387.6-.48-775.19-.91-91.71-.12-183.42-.22c-2.14,0-4.29.29-6.43.44V66q65.46.1,130.93.21,151.68.15,303.36.26,279.36.34,558.73.73c2.33,0,4.65-.13,7-.2Z"/>
                  <path :fill="category.color" d="M1000,60c-2.16-.07-4.32-.2-6.48-.2H839.71a31.23,31.23,0,0,1-5.48-.2,8.48,8.48,0,0,1-7-9,8.23,8.23,0,0,1,8-7.48c1.66-.12,3.33,0,5,0L1000,43Z"/>
                  <path :fill="category.color" d="M597,32q39.21,0,78.42,0c5.87,0,8.89,2.17,9.62,6.6,1,5.87-2.7,10-9.31,10.11-12.49.13-25,0-37.47,0l-116.38,0c-1.5,0-3,.06-4.49,0-5.6-.33-9.18-3.71-9.14-8.56s3.54-8.14,9.33-8.16c26.47-.07,52.94,0,79.42,0Z"/>
                </svg>
                <span :style="`background-color: ${category.color}`" class="d-block px-1 text-subtitle-2 text-truncate white--text text-center text-uppercase">
                  {{ category.name }}
                </span>
              </v-img>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </template>
    <!--Search-->
    <v-col cols="12">
      <v-text-field clearable v-model="search" prepend-inner-icon="mdi-magnify" label="Procurar"
                    hide-details="auto" @keyup.native.enter="searchBusinesses" @click:clear="fetchBusinesses"/>
    </v-col>
    <!--Map Dialog-->
    <v-col cols="12">
      <v-dialog v-model="dialog" width="100%" max-width="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn block text color="primary" v-bind="attrs" v-on="on">
            <v-icon left>mdi-map</v-icon> Procurar no mapa
          </v-btn>
        </template>
        <a-leaflet-map-positions :aspect-ratio="$vuetify.breakpoint.xsOnly ? 1 : 16/9"/>
      </v-dialog>
    </v-col>
    <!--Estabelecimentos-->
    <template v-if="businesses">
      <!--Header-->
      <v-col cols="12">
        <h1 id="businesses" class="text-subtitle-1 font-weight-light text-uppercase">Estabelecimentos</h1>
      </v-col>
      <!--Businesses-->
      <template v-if="businesses.data.length">
        <v-col cols="12" md="6" xl="4" v-for="business in businesses.data" :key="business.id">
          <v-card flat outlined :to="{ name: 'business', params: { business: business.id } }">
            <a-business-tile :business="business"/>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-pagination v-model="page" :length="businesses.meta.last_page" :total-visible="7"
                        @input="fetchBusinesses"/>
        </v-col>
      </template>
      <!--No Data-->
      <template v-else>
        <v-col cols="12">
          <v-alert border="left" colored-border color="primary">
            <v-row dense align="center">
              <v-col class="shrink">
                <v-icon color="primary" size="36">mdi-format-list-bulleted-square</v-icon>
              </v-col>
              <v-col class="grow">
                <span class="d-block">Ainda não existem no momento estabelecimentos cadastrados que satisfaçam a sua pesquisa.</span>
                <span class="d-block text-caption">Sujira ao seu restaurante preferido que esteja no PointUrbano!</span>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </template>
    </template>
  </v-row>
</template>

<script>
import ABusinessTile from "@/components/ABusinessTile";
import ALeafletMapPositions from "../../components/ALeafletMapPositions";

export default {
  name: 'a-dashboard',
  components: {
    ALeafletMapPositions,
    ABusinessTile
  },
  data: () => ({
    page: 1,
    businesses: null,
    categories: null,
    spots: null,
    search: '',
    dialog: false,
  }),
  async created() {
    if (this.service === 'DELIVERY' && !this.address) {
      await this.$router.push({ name: 'service' });
    } else {
      await this.getCurrentPosition();
      await this.fetchSpots();
      await this.fetchCategories();
      await this.fetchBusinesses();
    }
  },
  methods: {
    serviceName(service) {
      switch (service) {
        case 'DELIVERY':
          return 'Delivery';
        case 'PICK_UP':
          return 'Retirada';
        case 'DINE_IN':
          return 'Em Mesa';
        default:
          return 'Não definido';
      }
    },
    pullToRefresh() {
      if(document.scrollingElement.scrollTop === 0) {
        this.fetchBusinesses();
      }
    },
    async fetchSpots() {
      const spots = await this.$axios.get(`/spots`, { params: {visible: 1, period: 1, all: 1, order_by: 'order', direction: 'asc' } });
      this.spots = spots.data;
    },
    async fetchCategories() {
      const categories = await this.$axios.get(`/categories`, { params: { visible: 1, all: 1, order_by: 'order', direction: 'asc' } });
      this.categories = categories.data;
    },
    async fetchBusinesses(params) {
      const location = this.service === 'DELIVERY' && this.address ? this.address.location : this.location;
      const businesses = await this.$axios.get(`/businesses`, { params: { ...params, location: location, page: this.page, service: this.service, nearly: 1, active: 1, subscribed: 1 } });
      this.businesses = businesses.data;
    },
    async searchBusinesses() {
      const location = this.service === 'DELIVERY' && this.address ? this.address.location : this.location;
      const businesses = await this.$axios.get(`/businesses`, { params: { location: location, service: this.service, name: this.search, all: 1, nearly: 1, active: 1, subscribed: 1 } });
      this.businesses = businesses.data;
      await this.$vuetify.goTo('#businesses');
    },
    async getCurrentPosition() {
      let payload = { type: "Point", coordinates: [-53.92089843750001, -13.882244132383343] };
      if (navigator.geolocation) {
        const position = await this.$geolocation()
        payload.coordinates = [position.coords.longitude, position.coords.latitude]
      }
      await this.$store.dispatch('setCurrentPosition', payload);
    },
  },
  computed: {
    location() {
      return this.$store.getters.getCurrentPosition;
    },
    address() {
      return this.$store.getters.getAddress;
    },
    service: {
      get: function () {
        return this.$store.getters.getService;
      },
      set: function (service) {
        this.$store.dispatch('setService', service);
      },
    },
  }
};
</script>
