<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Header-->
    <v-col cols="12" v-if="business">
      <v-card flat outlined :to="{ name: 'business', params: { id: business.data.id } }" exact>
        <a-business-tile :business="business.data"/>
      </v-card>
    </v-col>
    <!--Coupons-->
    <template v-if="coupons">
      <v-col cols="12" class="mt-2">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cupons</h1>
      </v-col>
      <v-col cols="12" md="3" lg="2" xl="1" v-for="coupon in coupons.data" :key="coupon.id">
        <v-card flat outlined>
          <v-card-title v-if="coupon.type === 'DECIMAL'">
            {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(coupon.amount) }} de desconto
          </v-card-title>
          <v-card-title v-if="coupon.type === 'PERCENTAGE'">
            {{ coupon.percentage }}% de desconto
          </v-card-title>
          <v-card-title v-if="coupon.type === 'DELIVERY_FREE'">
            Frete Grátis
          </v-card-title>
          <v-card-title v-if="coupon.type === 'FIRST_ORDER_FREE'">
            Primeira Compra Grátis
          </v-card-title>
          <v-divider style="border-style: dashed"/>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle v-if="coupon.min_amount">
                Valor Mínimo: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(coupon.min_amount) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="coupon.max_amount">
                Valor Máximo: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(coupon.max_amount) }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Quantidade: {{ coupon.redeemed }} de {{ coupon.quantity }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                Valido até {{ DateTime.fromSQL(coupon.valid_until).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions>
            <v-btn depressed block @click="redeemCoupon(coupon)">
              Resgatar Cupom
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";
  import ABusinessTile from "@/components/ABusinessTile";

  export default {
    name: 'a-coupons',
    components: {ABusinessTile},
    data: () => ({
      DateTime: DateTime,
    }),
    created() {
      this.$store.dispatch('fetchCouponBusiness', {
        business: this.$route.params.business
      });
      this.$store.dispatch('fetchCouponCoupons', {
        business: this.$route.params.business
      });
    },
    methods: {
      redeemCoupon(coupon) {
        this.$store.dispatch('redeemCouponCoupon', {
          user: this.user.id,
          payload: { coupon_id: coupon.id }
        }).then(() => {
          alert('O cupom foi resgatado!');
        });
      },
    },
    computed: {
      user() {
        return this.$store.getters.getLoginAuthUser;
      },
      coupons() {
        return this.$store.getters.getCouponCoupons;
      },
    },
  };
</script>
