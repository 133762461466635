import ACardList from './index'
import ACardForm from './form'

export default [
  {
    path: '/cards/',
    component: ACardList,
    name: 'cards',
    meta: {
     requiresAuth: true
    }
  },
  {
    path: '/cards/create',
    component: ACardForm,
    name: 'cards_create',
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/cards/:card/update',
    component: ACardForm,
    name: 'cards_update',
    meta: {
      requiresAuth: true
    }
  },
];
