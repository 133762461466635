<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Featured Items-->
    <template v-if="featuredItems && featuredItems.data.length">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Destaques</h1>
      </v-col>
      <v-col cols="12" md="6" class="mb-4" v-for="(item, index) in featuredItems.data" :key="`featured-${index}`">
<!--        <v-list-item two-line class="px-0">-->
<!--          <v-list-item-avatar rounded size="60">-->
<!--            <v-img :src="item.thumbnail ? item.thumbnail.url : require('../../assets/placeholder.png')" aspect-ratio="1"/>-->
<!--          </v-list-item-avatar>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>{{ item.name }}</v-list-item-title>-->
<!--            <v-list-item-subtitle class="caption" v-if="item.description">{{ item.description }}</v-list-item-subtitle>-->
<!--            <v-list-item-subtitle>-->
<!--              <template v-if="item.options.length > 0">-->
<!--                A partir de {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.min_amount) }}-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span :style="item.promotion ? {'text-decoration': 'line-through'} : ''"> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}</span>-->
<!--                <span v-if="item.promotion"> - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.promotion_amount) }}</span>-->
<!--              </template>-->
<!--            </v-list-item-subtitle>-->
<!--            <v-list-item-subtitle v-if="item.loyalty_pay > 0">-->
<!--              <v-icon x-small color="primary" class="pb-1">icon-poker-chip</v-icon> Pontos: {{ item.loyalty_pay }}-->
<!--            </v-list-item-subtitle>-->
<!--          </v-list-item-content>-->
<!--        </v-list-item>-->

        <v-row dense>
          <v-col class="grow title">{{ item.name }}</v-col>
          <v-col class="shrink">
            <template v-if="item.options.length > 0">
              A partir de <span class="title text--primary">{{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.min_amount) }}</span>
            </template>
            <template v-else>
              <span class="title text--primary" :style="item.promotion ? {'text-decoration': 'line-through'} : ''"> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}</span>
              <span class="title text--primary" v-if="item.promotion"> - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.promotion_amount) }}</span>
            </template>
          </v-col>
          <v-col cols="12" class="subtitle-2">{{ item.description }}</v-col>
        </v-row>

<!--        <v-card flat :to="{ name: 'mount', params: { business: $route.params.business, item: item.id } }">-->
<!--          <a-item-tile :item="item"/>-->
<!--        </v-card>-->
      </v-col>
    </template>
    <!--Promotion items-->
    <template v-if="promotionItems && promotionItems.data.length">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Itens em Promoção</h1>
      </v-col>
      <v-col cols="12" md="6" v-for="(item, index) in promotionItems.data" :key="`promotion-${index}`">
        <v-card flat outlined :to="{ name: 'mount', params: { business: $route.params.business, item: item.id } }">
          <a-item-tile :item="item"/>
        </v-card>
      </v-col>
    </template>
    <!--Groups-->
    <template v-if="groups">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Categorias</h1>
      </v-col>
      <v-col cols="4" sm="3" md="2" lg="1" v-for="group in groups.data" :key="group.id">
        <v-card flat :to="{ name: 'items', params: { business: $route.params.business, group: group.id } }">
          <v-img class="align-end" :src="group.thumbnail ? group.thumbnail.url : require('../../assets/placeholder.png')" aspect-ratio="1">
            <svg class="d-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 159">
              <path :fill="group.color" d="M1000,92v67H0v-6a44.29,44.29,0,0,0,4.43.44q67.25.13,134.49.2c11.19,0,14.46-4.25,11.43-15.25h4.47q184.24.14,368.47.26,140.49.09,281,.11c1.83,0,4.06.37,5.38-.52,2-1.33,4.63-3.66,4.56-5.47-.09-2.15-2.38-4.82-4.43-6.09-1.74-1.07-4.52-.62-6.83-.62q-203-.06-406-.08-132.5,0-265-.08-66,0-132,.1V91c1.5-.07,3-.21,4.49-.21l305.85.34,431.79.53Q871.06,91.82,1000,92Z"/>
              <path :fill="group.color" d="M322,0c2.41.39,4.81,1.11,7.22,1.12q120.21.09,240.43.07h3c3.35,0,7.35-.49,7.41,4.37s-4,4.37-7.33,4.37l-230.44,0h-6.92c.33,1.64.51,2.86.83,4,3.74,13.47-4.77,25.62-18.79,26.09-12.15.41-24.32.08-36.49.08L0,40V0Z"/>
              <path :fill="group.color" d="M1000,72c-11.65-.1-23.31-.3-35-.31q-387.6-.48-775.19-.91-91.71-.12-183.42-.22c-2.14,0-4.29.29-6.43.44V66q65.46.1,130.93.21,151.68.15,303.36.26,279.36.34,558.73.73c2.33,0,4.65-.13,7-.2Z"/>
              <path :fill="group.color" d="M1000,60c-2.16-.07-4.32-.2-6.48-.2H839.71a31.23,31.23,0,0,1-5.48-.2,8.48,8.48,0,0,1-7-9,8.23,8.23,0,0,1,8-7.48c1.66-.12,3.33,0,5,0L1000,43Z"/>
              <path :fill="group.color" d="M597,32q39.21,0,78.42,0c5.87,0,8.89,2.17,9.62,6.6,1,5.87-2.7,10-9.31,10.11-12.49.13-25,0-37.47,0l-116.38,0c-1.5,0-3,.06-4.49,0-5.6-.33-9.18-3.71-9.14-8.56s3.54-8.14,9.33-8.16c26.47-.07,52.94,0,79.42,0Z"/>
            </svg>
            <span :style="`background-color: ${group.color}`" class="d-block px-1 text-subtitle-2 text-truncate white--text text-center text-uppercase">
              {{ group.name }}
            </span>
          </v-img>
        </v-card>
      </v-col>
    </template>
    <!--Coupons-->
    <template v-if="business && business.data.has_coupon">
      <v-btn fixed bottom right fab color="primary" :to="{ name: 'business.coupons', params: { business: $route.params.business } }">
        <v-icon>mdi-ticket-percent</v-icon>
      </v-btn>
    </template>
  </v-row>
</template>

<script>
import AItemTile from "@/components/AItemTile";

export default {
  name: 'a-group',
  components: { AItemTile },
  data: () => ({
    business: null,
    featuredItems: null,
    promotionItems: null,
    groups: null,
  }),
  async created() {
    const business = await this.$axios.get(`/businesses/${this.$route.params.business}`);
    this.business = business.data;

    const featuredItems = await this.$axios.get(`/businesses/${this.$route.params.business}/items`, { params: { featured: 1, all: 1, active: 1 } });
    this.featuredItems = featuredItems.data;

    const promotionItems = await this.$axios.get(`/businesses/${this.$route.params.business}/items`, { params: { promotion: 1, all: 1, active: 1 } });
    this.promotionItems = promotionItems.data;

    const groups = await this.$axios.get(`/businesses/${this.$route.params.business}/groups`, { params: { all: 1, order_by: 'order', direction: 'asc' } });
    this.groups = groups.data;
  },
};
</script>
