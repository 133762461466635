<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Cartão</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'cards_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="cards">
      <v-col cols="12">
        <v-text-field v-model="cardsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchCards()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
                    :items="cards.data"
                    :page.sync="cards.meta.current_page"
                    :items-per-page.sync="cards.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="12" sm="3" md="2" lg="2" xl="2" v-for="item in props.items" :key="item.id">
                <v-card flat outlined>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.exp_month }}/{{ item.exp_year }}</v-list-item-subtitle>
                      <v-list-item-subtitle>**** {{ item.last_four }}</v-list-item-subtitle>
                      <v-list-item-subtitle>
                        <v-icon small v-if="item.brand === 'VISA'">icon-visa</v-icon>
                        <v-icon small v-if="item.brand === 'MASTERCARD'">icon-mastercard</v-icon>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn :to="{ name: 'cards_update', params: { card: item.id } }" icon class="mr-0">
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                      <v-btn @click="destroyCard(item)" icon class="mr-0">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="cards.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-card-list',
    data: () => ({
      page: 1,
      cardsSearch: '',
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toCardsPage', {
          user: this.user.id,
          search: this.cardsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchCards();
    },
    methods: {
      fetchCards() {
        this.$store.dispatch('fetchCards', {
          user: this.user.id,
        });
      },
      searchCards() {
        this.$store.dispatch('searchCards', {
          user: this.user.id,
          search: this.cardsSearch
        });
      },
      destroyCard(card) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteCard', {
            user: this.user.id,
            card: card.id
          }).then(() => {
            this.fetchCards();
          });
        }
      },
    },
    computed: {
      cards() {
        return this.$store.getters.getCards;
      },
      user(){
        return this.$store.getters.getLoginAuthUser;
      },
    },
  };
</script>
