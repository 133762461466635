import resource from "../../resource";

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {
  register(context, data) {
    return resource.post('/users', data)
      .then((response) => {
        return Promise.resolve(response);
      }).catch((error) => {
        return Promise.reject(error);
      });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
