<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informações</h1>
    </v-col>
    <!--Header-->
    <template v-if="business">
      <v-col cols="12">
        <a-leaflet-map-business :business="business.data" :aspect-ratio="$vuetify.breakpoint.xsOnly ? 16/9 : 21/9"/>
      </v-col>
      <v-col cols="12" v-if="business.data.location">
        <v-btn depressed block @click="openMap(business.data.location)" target="_blank">Abrir no Mapa</v-btn>
      </v-col>

      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Sobre</h1>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-title>
            {{ business.data.name }}
          </v-card-title>
          <v-card-text>
            {{ business.data.description }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Estrelas</h1>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text class="text-center">
            <h1 class="text-h3 text-uppercase pt-0">{{ business.data.rating }}</h1>
            <v-rating dense half-increments readonly background-color="gray lighten-1" v-model="business.data.rating"/>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <!--Today Periods-->
    <template v-if="todayPeriods && todayPeriods.data.length">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Horários de hoje</h1>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Dia</th>
                  <th class="text-left">Abertura</th>
                  <th class="text-left">Fechamento</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="todayPeriod in todayPeriods.data" :key="todayPeriod.id">
                  <td>{{ days(todayPeriod.day) }}</td>
                  <td>{{ todayPeriod.time_start }}</td>
                  <td>{{ todayPeriod.time_end }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <!--Periods-->
    <template v-if="periods && periods.data.length">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Horários da Semana</h1>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">Dia</th>
                  <th class="text-left">Abertura</th>
                  <th class="text-left">Fechamento</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="period in periods.data" :key="period.id">
                  <td>{{ days(period.day) }}</td>
                  <td>{{ period.time_start }}</td>
                  <td>{{ period.time_end }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <!--Payment Methods-->
    <template>
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Pagamentos na entrega</h1>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined>
          <v-card-text>
            <v-row dense>
              <template v-if="paymentMethodsCurrency">
                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Dinheiro</h1>
                </v-col>

                <v-col cols="12">
                  <v-chip label v-for="paymentMethod in paymentMethodsCurrency" :key="paymentMethod.id">
                    <v-avatar left>
                      <v-icon>mdi-cash</v-icon>
                    </v-avatar>
                    {{ paymentMethod.name }}
                  </v-chip>
                </v-col>
              </template>
              <template v-if="paymentMethodsCreditCard">
                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Crédito</h1>
                </v-col>

                <v-col cols="12">
                  <v-chip label class="mr-2 mb-2" v-for="paymentMethod in paymentMethodsCreditCard" :key="paymentMethod.id">
                    <v-avatar tile left>
                      <v-icon>mdi-credit-card</v-icon>
                    </v-avatar>
                    {{ paymentMethod.name }}
                  </v-chip>
                </v-col>
              </template>
              <template v-if="paymentMethodsDebitCard">
                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Cartão de Débito</h1>
                </v-col>

                <v-col cols="12">
                  <v-chip label class="mr-2 mb-2" v-for="paymentMethod in paymentMethodsDebitCard" :key="paymentMethod.id">
                    <v-avatar tile left>
                      <v-icon>mdi-credit-card</v-icon>
                    </v-avatar>
                    {{ paymentMethod.name }}
                  </v-chip>
                </v-col>
              </template>
              <template v-if="paymentMethodsMealTicket">
                <v-col cols="12">
                  <h1 class="text-subtitle-1 font-weight-light text-uppercase">Ticket</h1>
                </v-col>

                <v-col cols="12">
                  <v-chip label class="mr-2 mb-2" v-for="paymentMethod in paymentMethodsMealTicket" :key="paymentMethod.id">
                    <v-avatar tile left>
                      <v-icon>mdi-credit-card</v-icon>
                    </v-avatar>
                    {{ paymentMethod.name }}
                  </v-chip>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </template>
    <!--Ratings-->
    <template v-if="ratings && ratings.data.length">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Avaliações</h1>
      </v-col>
      <v-col cols="12" md="6" v-for="rating in ratings.data" :key="rating.id">
        <v-card flat outlined>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <v-rating dense small half-increments readonly background-color="gray lighten-1" v-model="rating.rating"/>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-icon small class="mt-n1">mdi-account</v-icon> {{ rating.comment }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon small class="mt-n1">mdi-domain</v-icon> {{ rating.replica }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </template>
    <!--Footer-->
    <v-col cols="12">
      <v-alert border="left" colored-border color="primary">
        <v-row dense align="center">
          <v-col class="shrink">
            <v-icon color="primary" size="36">mdi-information-outline</v-icon>
          </v-col>
          <v-col class="grow">
            <span class="d-block">Importante!</span>
            <span class="d-block text-caption">O PointUrbano é um aplicativo gratuito e todos os preços apresentados no cardápio são definidos pelo próprio restaurante.</span>
          </v-col>
        </v-row>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
  import ALeafletMapBusiness from "../../components/ALeafletMapBusiness";

  export default {
    name: 'a-business',
    components: {
      ALeafletMapBusiness
    },
    data: () => ({
      business: null,
      periods: null,
      todayPeriods: null,
      paymentMethodsCreditCard: null,
      paymentMethodsDebitCard: null,
      paymentMethodsMealTicket: null,
      paymentMethodsCurrency: null,
      ratings: null,
    }),
    async created() {
      const business = await this.$axios.get(`/businesses/${this.$route.params.business}`);
      this.business = business.data;

      const periods = await this.$axios.get(`/businesses/${this.$route.params.business}/periods`, { params: { all: 1 } });
      this.periods = periods.data;

      const todayPeriods = await this.$axios.get(`/businesses/${this.$route.params.business}/periods`, { params: { today_periods: 1, all: 1 } });
      this.todayPeriods = todayPeriods.data;

      const paymentMethods = await this.$axios.get(`/businesses/${this.$route.params.business}/payment_methods`, { params: { all: 1 } });
      this.paymentMethodsCreditCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CREDIT_CARD')
      this.paymentMethodsDebitCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'DEBIT_CARD')
      this.paymentMethodsMealTicket = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'MEAL_TICKET')
      this.paymentMethodsCurrency = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CURRENCY')

      const ratings = await this.$axios.get(`/businesses/${this.$route.params.business}/ratings`, { params: { all: 1, last_days: 1 } });
      this.ratings = ratings.data;
    },
    methods: {
      openMap(location) {
        let geocoords = `${location.coordinates[1]},${location.coordinates[0]}`;
        if (/android/i.test(navigator.userAgent)) {
          window.open(`geo:0,0?q=${geocoords}`, '_system');
        } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
          window.open(`maps://?q=${geocoords}`, '_system');
        } else if (/Win/.test(navigator.userAgent)) {
          window.open(`https://www.google.com/maps/search/?api=1&query=${geocoords}`, '_system');
        } else if (/Mac/i.test(navigator.userAgent)) {
          window.open(`http://maps.apple.com/?ll=${geocoords}`, '_system');
        }
      },
      days(day){
        switch (day) {
          case 1:
            return 'Domingo';
          case 2:
            return 'Segunda';
          case 3:
            return 'Terça';
          case 4:
            return 'Quarta';
          case 5:
            return 'Quinta';
          case 6:
            return 'Sexta';
          case 7:
            return 'Sábado';
          default:
            return 'Indefinido';
        }
      },
    },
  };
</script>
