<template>
  <v-row dense align-content="start" class="fill-height">
    <!--    <template v-if="cartPaymentType === 'IN_APP'">-->
    <!--      <v-col cols="12">-->
    <!--        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Insira as Informações do Cartão</h1>-->
    <!--      </v-col>-->

    <!--      <v-col cols="12" v-if="cards">-->
    <!--        <v-item-group v-model="cartCard">-->
    <!--          <v-row dense>-->
    <!--            <template v-for="card in cards.data">-->
    <!--              <v-item :value="card.id" v-slot:default="{ active, toggle }" :key="card.id">-->
    <!--                <v-col cols="12" sm="3" md="2" lg="2" xl="2" @click="toggle">-->
    <!--                  <v-card flat outlined :dark="active" :color="active ? 'primary' : ''">-->
    <!--                    <v-list-item two-line>-->
    <!--                      <v-list-item-content>-->
    <!--                        <v-list-item-title>{{ card.name }}</v-list-item-title>-->
    <!--                        <v-list-item-subtitle>{{ card.exp_month }}/{{ card.exp_year }}</v-list-item-subtitle>-->
    <!--                        <v-list-item-subtitle>**** {{ card.last_four }}</v-list-item-subtitle>-->
    <!--                      </v-list-item-content>-->
    <!--                      <v-list-item-action>-->
    <!--                        <v-icon v-if="card.brand === 'VISA'">icon-visa</v-icon>-->
    <!--                        <v-icon v-if="card.brand === 'MASTERCARD'">icon-mastercard</v-icon>-->
    <!--                      </v-list-item-action>-->
    <!--                    </v-list-item>-->
    <!--                  </v-card>-->
    <!--                </v-col>-->
    <!--              </v-item>-->
    <!--            </template>-->
    <!--          </v-row>-->
    <!--        </v-item-group>-->
    <!--      </v-col>-->
    <!--      <v-col cols="12">-->
    <!--        <v-btn block depressed @click="$router.push({ name: 'card_create' })">-->
    <!--          Criar Novo Cartão-->
    <!--        </v-btn>-->
    <!--      </v-col>-->
    <!--    </template>-->
    <template v-if="cartPaymentType === 'CURRENCY' && paymentMethodsCurrency">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Selecione a Moeda</h1>
      </v-col>

      <v-col cols="12">
        <v-item-group v-model="cartPaymentMethod">
          <v-row dense>
            <template v-for="paymentMethod in paymentMethodsCurrency">
              <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }" :key="paymentMethod.id">
                <v-col cols="12" sm="6" md="3" lg="2" xl="1">
                  <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                    <v-list-item two-line>
                      <v-list-item-avatar>
                        <v-icon>mdi-cash</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                        <v-list-item-subtitle>Moeda</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-item>
            </template>
          </v-row>
        </v-item-group>
      </v-col>

      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase pt-2">Precisa de troco?</h1>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="cartCashChange" label="Troco Para*" type="number"
                      :rules="[$rules.required]"/>
      </v-col>
    </template>
    <template v-if="cartPaymentType === 'CREDIT_CARD' && paymentMethodsCreditCard">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Selecione a Bandeira de Crédito</h1>
      </v-col>

      <v-col cols="12">
        <v-item-group v-model="cartPaymentMethod">
          <v-row dense>
            <template v-for="paymentMethod in paymentMethodsCreditCard">
              <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }" :key="paymentMethod.id">
                <v-col cols="12" sm="6" md="3" lg="2" xl="1">
                  <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                    <v-list-item two-line>
                      <v-list-item-avatar>
                        <v-icon>mdi-credit-card</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                        <v-list-item-subtitle>Crédito</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-item>
            </template>
          </v-row>
        </v-item-group>
      </v-col>
    </template>
    <template v-if="cartPaymentType === 'DEBIT_CARD' && paymentMethodsDebitCard">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Selecione a Bandeira de Débito</h1>
      </v-col>

      <v-col cols="12">
        <v-item-group v-model="cartPaymentMethod">
          <v-row dense>
            <template v-for="paymentMethod in paymentMethodsDebitCard">
              <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }" :key="paymentMethod.id">
                <v-col cols="12" sm="6" md="3" lg="2" xl="1">
                  <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                    <v-list-item two-line>
                      <v-list-item-avatar>
                        <v-icon>mdi-credit-card</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                        <v-list-item-subtitle>Débito</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-item>
            </template>
          </v-row>
        </v-item-group>
      </v-col>
    </template>
    <template v-if="cartPaymentType === 'MEAL_TICKET' && paymentMethodsMealTicket">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Selecione a Bandeira do Ticket</h1>
      </v-col>

      <v-col cols="12">
        <v-item-group v-model="cartPaymentMethod">
          <v-row dense>
            <template v-for="paymentMethod in paymentMethodsMealTicket">
              <v-item :value="paymentMethod.id" v-slot:default="{ active, toggle }" :key="paymentMethod.id">
                <v-col cols="12" sm="6" md="3" lg="2" xl="1">
                  <v-card flat outlined :dark="active" :color="active ? 'primary' : ''" @click="toggle">
                    <v-list-item two-line>
                      <v-list-item-avatar>
                        <v-icon>mdi-credit-card</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ paymentMethod.name }}</v-list-item-title>
                        <v-list-item-subtitle>Vale</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-col>
              </v-item>
            </template>
          </v-row>
        </v-item-group>
      </v-col>
    </template>

    <v-col cols="12" class="pt-4 text-right">
      <v-btn text class="mr-1" @click="$router.back()">Voltar</v-btn>
      <v-btn depressed color="primary" @click="toCartCoupons">Continuar</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-cart-payment-method',
  data: () => ({
    paymentMethodsCreditCard: null,
    paymentMethodsDebitCard: null,
    paymentMethodsMealTicket: null,
    paymentMethodsCurrency: null,
    cards: null,
  }),
  async created() {
    const cards = await this.$axios.get(`/users/${this.user.id}/cards`);
    this.cards = cards.data;

    const paymentMethods = await this.$axios.get(`/businesses/${this.cartBusiness}/payment_methods`, { params: { all: 1 } });
    this.paymentMethodsCreditCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CREDIT_CARD')
    this.paymentMethodsDebitCard = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'DEBIT_CARD')
    this.paymentMethodsMealTicket = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'MEAL_TICKET')
    this.paymentMethodsCurrency = paymentMethods.data.data.filter(paymentMethod => paymentMethod.type === 'CURRENCY')
  },
  methods: {
    toCartCoupons() {
      switch (this.cartPaymentType) {
        case "IN_APP":
          if(!this.cartCard) {
            alert('Selecione um cartão!');
            return;
          }
          break;
        case "CURRENCY":
          if(!this.cartPaymentMethod) {
            alert('Selecione uma moeda!');
            return;
          }
          break;
        case "CREDIT_CARD":
          if(!this.cartPaymentMethod) {
            alert('Selecione uma bandeira de cartão de crédito!');
            return;
          }
          break;
        case "DEBIT_CARD":
          if(!this.cartPaymentMethod) {
            alert('Selecione uma bandeira de cartão de débito!');
            return;
          }
          break;
        case "MEAL_TICKET":
          if(!this.cartPaymentMethod) {
            alert('Selecione uma bandeira de vale refeição!');
            return;
          }
          break;
      }
      this.$router.push({ name: 'cart_coupon' });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getLoginAuthUser;
    },
    cartBusiness() {
      return this.$store.getters.getCartBusiness;
    },
    cartCashChange: {
      get: function () {
        return this.$store.getters.getCartCashCharge;
      },
      set: function (status) {
        this.$store.dispatch('setCartCashChange', status);
      },
    },
    cartPaymentType: {
      get: function () {
        return this.$store.getters.getCartPaymentType;
      },
      set: function (status) {
        this.$store.dispatch('setCartPaymentType', status);
      },
    },
    cartPaymentMethod: {
      get: function () {
        return this.$store.getters.getCartPaymentMethod;
      },
      set: function (status) {
        this.$store.dispatch('setCartPaymentMethod', status);
      },
    },
    cartCard: {
      get: function () {
        return this.$store.getters.getCartCard;
      },
      set: function (status) {
        this.$store.dispatch('setCartCard', status);
      },
    },
  },
};
</script>
