<template>
  <v-row dense align-content="start" class="fill-height">
    <!--Header-->
    <template v-if="business">
      <v-col cols="12">
        <v-card flat outlined :to="{ name: 'business', params: { id: business.data.id } }" exact>
          <a-business-tile :business="business.data"/>
        </v-card>
      </v-col>
    </template>
    <!--Search-->
    <v-col cols="12">
      <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                    label="Procurar" clearable @keyup.native.enter="searchItems" @click:clear="search = ''"/>
    </v-col>
    <!--Items-->
    <template v-if="items">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Items</h1>
      </v-col>
      <v-col cols="12" md="6" v-for="item in items.data" :key="item.id">
        <v-card flat outlined :to="{ name: 'mount', params: { business: $route.params.business, item: item.id } }">
          <a-item-tile :item="item"/>
        </v-card>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import AItemTile from "@/components/AItemTile";
import ABusinessTile from "../../components/ABusinessTile";

export default {
  name: 'a-items',
  components: {
    ABusinessTile,
    AItemTile
  },
  data: () => ({
    business: null,
    items: null,
    search: ''
  }),
  async created() {
    const business = await this.$axios.get(`/businesses/${this.$route.params.business}`);
    this.business = business.data;

    const items = await this.$axios.get(`/businesses/${this.$route.params.business}/groups/${this.$route.params.group}/items`, { params: { all: 1, order_by: 'id', direction: 'asc' } });
    this.items = items.data;
  },
  methods: {
    async searchItems() {
      const items = await this.$axios.get(`/businesses/${this.$route.params.business}/groups/${this.$route.params.group}/items`, { params: { name: this.search, all: 1, visible: 1 } });
      this.items = items.data;
    },
  }
}
</script>
