import AMenu from './index'

export default [
  {
    path: '/menu/:business',
    name: 'menu',
    component: AMenu,
    meta: {
      requiresAuth: true,
    },
  },
];
