<template>
  <v-row dense align-content="start" class="fill-height">
    <template v-if="service === 'DELIVERY'">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">O Pedido Será Entregue Em:</h1>
      </v-col>

      <v-col cols="12" v-if="address">
        <v-card flat outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title>{{ address.address }} {{ address.number }}</v-list-item-title>
              <v-list-item-subtitle>Bairro: {{ address.district }}</v-list-item-subtitle>
              <v-list-item-subtitle>CEP: {{ address.cep }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ address.city.name }} - {{ address.city.state.uf }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </template>
    <template v-if="service === 'PICK_UP'">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informe Seu Nome</h1>
      </v-col>

      <v-col cols="12">
        <v-text-field v-model="cartPickUpNickname" label="Nome Para Entrega*" :rules="[$rules.required]"/>
      </v-col>
    </template>
    <template v-if="service === 'DINE_IN'">
      <v-col cols="12">
        <h1 class="text-subtitle-1 font-weight-light text-uppercase">Informe o Número da Mesa</h1>
      </v-col>

      <v-col cols="12" v-if="totems">
        <v-item-group v-model="cartTotem">
          <v-row dense>
            <v-col cols="4" sm="3" md="2" lg="2" xl="1" v-for="totem in totems.data" :key="totem.id">
              <v-item :value="totem.id" v-slot:default="{ active, toggle }">
                <v-card flat outlined :dark="active" :class="active ? 'primary' : ''" @click="toggle">
                  <v-responsive :aspect-ratio="1">
                    <div class="d-flex justify-center align-center fill-height">
                      <span class="display-1 text-truncate">{{ totem.number }}</span>
                    </div>
                  </v-responsive>
                </v-card>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-col>
    </template>

    <v-col cols="12" class="pt-4 text-right">
      <v-btn text class="mr-1" @click="$router.back()">Voltar</v-btn>
      <v-btn depressed color="primary" @click="toCartPaymentType">Continuar</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'a-cart-service',
  data: () => ({
    totems: null,
  }),
  async created() {
    const totems = await this.$axios.get(`/businesses/${this.cartBusiness}/totems`);
    this.totems = totems.data;
  },
  methods: {
    toCartPaymentType() {
      this.$store.dispatch('setCartService', this.service);
      switch (this.service) {
        case "DELIVERY":
          if (!this.address) {
            alert('Forneça um endereço de entrega!');
            return;
          }
          if (!this.address.location) {
            alert('O endereço não possui posição no mapa!');
            return;
          }
          this.$store.dispatch('setCartAddress', this.address.id);
          this.$store.dispatch('setCartLocation', this.address.location);
          this.$router.push({ name: 'cart_payment_type' });
          break;
        case "PICK_UP":
          if (!this.cartPickUpNickname) {
            alert('Forneça um nome para entrega!');
            return;
          }
          this.$store.dispatch('setCartLocation', this.location);
          this.$router.push({ name: 'cart_payment_type' });
          break;
        case "DINE_IN":
          if (!this.cartTotem) {
            alert('Forneça o número da mesa para entrega!');
            return;
          }
          this.$store.dispatch('setCartLocation', this.location);
          this.$router.push({ name: 'cart_payment_type' });
          break;
      }
    }
  },
  computed: {
    address() {
      return this.$store.getters.getAddress;
    },
    service() {
      return this.$store.getters.getService;
    },
    cartBusiness() {
      return this.$store.getters.getCartBusiness;
    },
    location() {
      return this.$store.getters.getCurrentPosition;
    },
    cartPickUpNickname: {
      get: function () {
        return this.$store.getters.getCartPickUpNickname;
      },
      set: function (status) {
        this.$store.dispatch('setCartPickUpNickname', status);
      },
    },
    cartTotem: {
      get: function () {
        return this.$store.getters.getCartTotem;
      },
      set: function (status) {
        this.$store.dispatch('setCartTotem', status);
      },
    },
  },
};
</script>
