import AService from './index'

export default [
  {
    path: '/service',
    name: 'service',
    component: AService,
    meta: {
      requiresAuth: true
    }
  },
];
