<template>
  <v-row dense align="center" justify="center" class="fill-height">
    <v-col cols="8" sm="4" md="3">
      <v-btn block depressed text color="primary" @click="switchCamera" class="mb-2">
        <v-icon>mdi-camera</v-icon>
      </v-btn>
      <v-responsive aspect-ratio="1">
        <qrcode-stream :camera="camera" @decode="onDecode"/>
      </v-responsive>
      <div class="text-center text-uppercase grey--text mt-1" style="font-size: 10px;">
        Aponte a câmera para o QR Code
      </div>
      <v-alert dense dismissible type="error" v-if="error">{{ error }}</v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  name: 'a-dashboard',
  components: {
    QrcodeStream,
  },
  data: () => ({
    camera: 'auto',
    capabilities: null,
    error: null,
  }),
  methods: {
    switchCamera () {
      switch (this.camera) {
        case 'front':
          this.camera = 'rear'
          break
        case 'rear':
          this.camera = 'front'
          break
      }
    },
    onDecode (decodedString) {
      if (decodedString.includes("client.pointurbano.com/")) {
        let url = new URL(`https://${decodedString}`);
        let totem = url.searchParams.get('totem');
        this.getCurrentPosition();
        this.$store.dispatch('setService', 'DINE_IN');
        this.$store.dispatch('setCartService', 'DINE_IN');
        this.$store.dispatch('setCartTotem', totem ? parseInt(totem) : null);
        this.$router.push(decodedString.replace('client.pointurbano.com/', ''));
      }
    },
    startFrontCamera () {
      this.camera = 'front'
    },
    async getCurrentPosition() {
      let payload = { type: "Point", coordinates: [-53.92089843750001, -13.882244132383343] };
      if (navigator.geolocation) {
        const position = await this.$geolocation()
        payload.coordinates = [position.coords.longitude, position.coords.latitude]
      }
      await this.$store.dispatch('setCurrentPosition', payload);
    },
  },
  computed: {
    user() {
      return this.$store.getters.getLoginAuthUser;
    }
  }
};
</script>

<style>
.qrcode-stream-camera{
  border-radius: 10px;
  border: 4px #666666 dashed;
}
</style>
