<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Endereço</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="$router.push({ name: 'addresses_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="addresses">
      <v-col cols="12">
        <v-text-field v-model="search" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="fetchAddresses()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
                    :items="addresses.data"
                    :page.sync="addresses.meta.current_page"
                    :items-per-page.sync="addresses.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="12" sm="6" md="6" lg="4" v-for="item in props.items" :key="item.id">
                <v-card flat outlined>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.address }} {{ item.number }}</v-list-item-title>
                      <v-list-item-subtitle>Bairro: {{ item.district }}</v-list-item-subtitle>
                      <v-list-item-subtitle>CEP: {{ item.cep }}</v-list-item-subtitle>
                      <v-list-item-subtitle>{{ item.city.name }} - {{ item.city.state.uf }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn :to="{ name: 'addresses_update', params: { address: item.id } }" icon class="mr-0">
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                      <v-btn @click="destroyAddress(item)" icon class="mr-0">
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="addresses.meta.last_page" :total-visible="7" @input="fetchAddresses()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'a-address-list',
    data: () => ({
      addresses: null,
      page: 1,
      search: '',
    }),
    created() {
      this.fetchAddresses();
    },
    methods: {
      async fetchAddresses() {
        const addresses = await this.$axios.get(`/users/${this.user.id}/addresses`, { params: { address: this.search, page: this.page } });
        this.addresses = addresses.data;
      },
      async destroyAddress(address) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          await this.$axios.delete(`/users/${this.user.id}/addresses/${address.id}`);
          await this.fetchAddresses();
        }
      },
    },
    computed: {
      user(){
        return this.$store.getters.getLoginAuthUser;
      },
    },
  };
</script>
