<template>
  <v-alert  v-bind="$attrs" class="mb-0" v-if="service" dense border="left" colored-border color="primary">
    <span v-if="this.service === 'DELIVERY'">Você esta optando pelo serviço de delivery!</span>
    <span v-if="this.service === 'PICK_UP'">Você esta optando pelo serviço de retirada no local!</span>
    <span v-if="this.service === 'DINE_IN'">Você esta optando pelo serviço de entrega em mesa!</span>
  </v-alert>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'a-service-alert',
  computed: {
    service() {
      return this.$store.getters.getService;
    }
  },
}
</script>
