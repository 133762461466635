import resource from "../../resource";

const state = {
  service: null,
  address: null,
  location: null,
  totem: null,
  pickUpNickname: '',
  paymentType: null,
  paymentMethod: null,
  cashChange: 0,
  card: null,
  coupon: null,
  documentFiscalNote: false,
  documentNumber: '',
  business: null,
  deliveryTax: 0,
  minOrderAmount: 0,
  paymentMethods: [],
  totems: [],
  items: []
};

const getters = {
  getCartService(state) {
    return state.service;
  },
  getCartAddress(state) {
    return state.address;
  },
  getCartLocation(state) {
    return state.location;
  },
  getCartTotem(state) {
    return state.totem;
  },
  getCartPickUpNickname(state) {
    return state.pickUpNickname;
  },
  getCartPaymentType(state) {
    return state.paymentType;
  },
  getCartPaymentMethod(state) {
    return state.paymentMethod;
  },
  getCartCashCharge(state) {
    return state.cashChange;
  },
  getCartCard(state) {
    return state.card;
  },
  getCartCoupon(state) {
    return state.coupon;
  },
  getCartDocumentFiscalNote(state) {
    return state.documentFiscalNote;
  },
  getCartDocumentNumber(state) {
    return state.documentNumber;
  },
  getCartBusiness(state) {
    return state.business;
  },
  getCartDeliveryTax(state) {
    return state.deliveryTax;
  },
  getCartMinOrderAmount(state) {
    return state.minOrderAmount;
  },
  getCartPaymentMethods(state) {
    return state.paymentMethods;
  },
  getCartTotems(state) {
    return state.totems;
  },
  getCartItems(state) {
    return state.items;
  },
  getCartItemsCount(state) {
    return state.items.length;
  },
  getCartTotal(state) {
    return state.items.reduce((itemCarry, itemCurrent) => {
      let itemPrice = itemCurrent.promotion ? itemCurrent.promotion_amount : itemCurrent.amount;
      let optionsSum = itemCurrent.options.reduce((optionsCarry, optionCurrent) => {
        let selectionsSum = optionCurrent.selections.reduce((selectionCarry, itemOptionCurrent) => {
          return selectionCarry + itemOptionCurrent.amount;
        }, 0);
        return optionsCarry + selectionsSum;
      }, itemPrice);
      return itemCarry + (optionsSum * itemCurrent.quantity);
    }, 0);
  },
};

const mutations = {
  SET_CART_SERVICE(state, data) {
    state.service = data;
  },
  SET_CART_ADDRESS(state, data) {
    state.address = data;
  },
  SET_CART_LOCATION(state, data) {
    state.location = data;
  },
  SET_CART_TOTEM(state, data) {
    state.totem = data;
  },
  SET_CART_PICK_UP_NICKNAME(state, data) {
    state.pickUpNickname = data;
  },
  SET_CART_PAYMENT_TYPE(state, data) {
    state.paymentType = data;
  },
  SET_CART_PAYMENT_METHOD(state, data) {
    state.paymentMethod = data;
  },
  SET_CART_CASH_CHANGE(state, data) {
    state.cashChange = data;
  },
  SET_CART_CARD(state, data) {
    state.card = data;
  },
  SET_CART_COUPON(state, data) {
    state.coupon = data;
  },
  SET_CART_DOCUMENT_FISCAL_NOTE(state, data) {
    state.documentFiscalNote = data;
  },
  SET_CART_DOCUMENT_NUMBER(state, data) {
    state.documentNumber = data;
  },
  SET_CART_BUSINESS(state, data) {
    state.business = data;
  },
  SET_CART_DELIVERY_TAX(state, data) {
    state.deliveryTax = data;
  },
  SET_CART_MIN_ORDER_AMOUNT(state, data) {
    state.minOrderAmount = data;
  },
  SET_CART_PAYMENT_METHODS(state, data) {
    state.paymentMethods = data;
  },
  SET_CART_TOTEMS(state, data) {
    state.totems = data;
  },
  SET_CART_ITEM(state, data) {
    state.items.unshift(data);
  },
  DELETE_CART_ITEM(state, data) {
    state.items = state.items.filter((item) => {
      return !(item.item_id === data.item_id);
    });
  },
  CLEAR_CART_ITEMS(state) {
    state.service = null;
    state.address = null;
    state.location = null;
    state.totem = null;
    state.pickUpNickname = '';
    state.paymentType = null;
    state.paymentMethod = null;
    state.cashChange = 0;
    state.card = null;
    state.coupon = null;
    state.documentFiscalNote = false;
    state.documentNumber = '';
    state.business = null;
    state.deliveryTax = 0;
    state.minOrderAmount = 0;
    state.paymentMethods = [];
    state.totems = [];
    state.items = [];
  },
  CHANGE_CART_ITEM_QUANTITY(state, data) {
    state.items = state.items.map((item) => {
      if (item.item_id === data.item_id) {
        item.quantity = data.quantity;
      }
      return item;
    });
  },
  INCREMENT_CART_ITEM(state, data) {
    state.items = state.items.map((item) => {
      if (item.item_id === data.item_id) {
        item.quantity++;
      }
      return item;
    });
  },
  DECREMENT_CART_ITEM(state, data) {
    state.items = state.items.map((item) => {
      if (item.item_id === data.item_id) {
        item.quantity--;
      }
      return item;
    });
  },
};

const actions = {
  setCartService(context, data) {
    context.commit('SET_CART_SERVICE', data);
  },
  setCartAddress(context, data) {
    context.commit('SET_CART_ADDRESS', data);
  },
  setCartLocation(context, data) {
    context.commit('SET_CART_LOCATION', data);
  },
  setCartTotem(context, data) {
    context.commit('SET_CART_TOTEM', data);
  },
  setCartPickUpNickname(context, data) {
    context.commit('SET_CART_PICK_UP_NICKNAME', data);
  },
  setCartPaymentType(context, data) {
    context.commit('SET_CART_PAYMENT_TYPE', data);
  },
  setCartPaymentMethod(context, data) {
    context.commit('SET_CART_PAYMENT_METHOD', data);
  },
  setCartCashChange(context, data) {
    context.commit('SET_CART_CASH_CHANGE', data);
  },
  setCartCard(context, data) {
    context.commit('SET_CART_CARD', data);
  },
  setCartCoupon(context, data) {
    context.commit('SET_CART_COUPON', data);
  },
  setCartDocumentFiscalNote(context, data) {
    context.commit('SET_CART_DOCUMENT_FISCAL_NOTE', data);
  },
  setCartDocumentNumber(context, data) {
    context.commit('SET_CART_DOCUMENT_NUMBER', data);
  },
  setCartBusiness(context, data) {
    context.commit('SET_CART_BUSINESS', data);
  },
  setCartDeliveryTax(context, data) {
    context.commit('SET_CART_DELIVERY_TAX', data);
  },
  setCartMinOrderAmount(context, data) {
    context.commit('SET_CART_MIN_ORDER_AMOUNT', data);
  },
  setCartPaymentMethods(context, data) {
    context.commit('SET_CART_PAYMENT_METHODS', data);
  },
  setCartTotems(context, data) {
    context.commit('SET_CART_TOTEMS', data);
  },
  setCartItem({ commit, state }, { item, business }) {
    if (state.business && state.business !== business) {
      commit('CLEAR_CART_ITEMS');
    }
    commit('SET_CART_BUSINESS', business);
    let hasItem = state.items.some((cartItem) => cartItem.item_id === item.item_id);
    if (hasItem && item.options && item.options.length === 0) { //se existe e não é um montável
      if (item.quantity > 1) {
        commit('CHANGE_CART_ITEM_QUANTITY', item);
      } else {
        commit('INCREMENT_CART_ITEM', item);
      }
    } else {
      commit('SET_CART_ITEM', item);
    }
  },
  deleteCartItem({ commit, state }, data) {
    commit('DELETE_CART_ITEM', data);
    if (state.items.length === 0) {
      commit('CLEAR_CART_ITEMS');
    }
  },
  clearCartItems(context) {
    context.commit('CLEAR_CART_ITEMS');
  },
  incrementCartItem(context, data) {
    context.commit('INCREMENT_CART_ITEM', data);
  },
  decrementCartItem({ commit, state }, data) {
    let item = state.items.find((item) => item.item_id === data.item_id);
    if (item.quantity > 1) {
      commit('DECREMENT_CART_ITEM', data);
    } else {
      commit('DELETE_CART_ITEM', data);
    }
  },
  makeCartOrder({ state }, { user }) {
    return resource.post(`/users/${user}/orders`, {
      service: state.service,
      address_id: state.address,
      location: state.location,
      totem_id: state.totem,
      pick_up_nickname: state.pickUpNickname,
      payment_type: state.paymentType,
      payment_method_id: state.paymentMethod,
      cash_change: state.cashChange,
      card_id: state.card,
      coupon_id: state.coupon,
      business_id: state.business,
      document_fiscal_note: state.documentFiscalNote,
      document_number: state.documentNumber,
      items: state.items,
    }).then((response) => {
      return Promise.resolve(response);
    }).catch(function (error) {
      return Promise.reject(error);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
