<template>
  <v-dialog v-model="dialog" scrollable width="100%" max-width="500">
    <template v-slot:activator="{ on }">
      <v-card flat outlined v-on="on">
        <v-list-item two-line>
          <v-list-item-avatar rounded size="80">
            <v-img :src="item.thumbnail ? item.thumbnail.url : require('../assets/placeholder.png')" aspect-ratio="1"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Valor: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itemValue(item)) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              {{ item.quantity }}x
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </template>
    <v-card flat outlined>
      <v-card-title>
        {{ item.name }}
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col class="shrink">
            <v-sheet dark rounded color="primary" class="px-2 d-flex align-center justify-space-between" height="36" width="100">
              <v-btn icon x-small @click="$store.dispatch('decrementCartItem', item)">
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <span class="pl-2 pr-2">{{ item.quantity }}</span>
              <v-btn icon x-small @click="$store.dispatch('incrementCartItem', item)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-sheet>
          </v-col>
          <v-col class="grow">
            <v-btn depressed height="36" elevation="0" block color="primary" @click="$store.dispatch('deleteCartItem', item)">
              <v-icon left>mdi-trash-can-outline</v-icon> Remover
            </v-btn>
          </v-col>

          <v-col cols="12" v-if="item.amount > 0">
            Valor: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.promotion ? item.promotion_amount : item.amount) }}
          </v-col>

          <v-col cols="12" v-if="item.options">
            <template v-for="option in item.options">
              <span class="d-block" :key="option.id">{{ option.name }}</span>
              <template v-for="selection in option.selections">
                <span class="d-block pl-5" :key="selection.id">{{ selection.quantity }}x {{ selection.name }} - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(selection.amount) }}</span>
              </template>
            </template>
          </v-col>

          <v-col cols="12">
            <v-divider style="border-style: dashed"/>
          </v-col>

          <v-col cols="12">
            Total: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itemValue(item)) }}
          </v-col>

          <v-col cols="12" v-if="item.comments">
            Obs. {{ item.comments }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="dialog = false">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-cart-item-tile',
    props: {
      item: Object,
    },
    data: () => ({
      dialog: false,
    }),
    methods: {
      itemValue(item) {
        let itemPrice = item.promotion ? item.promotion_amount : item.amount;
        let optionsSum = item.options.reduce(function (optionsAcumulador, optionAtual) {
          let selectionsSum = optionAtual.selections.reduce(function (selectionAcumulador, itemOptionAtual) {
            return selectionAcumulador + itemOptionAtual.amount;
          }, 0);
          return optionsAcumulador + selectionsSum;
        }, itemPrice);
        return optionsSum * item.quantity;
      }
    }
  }
</script>
