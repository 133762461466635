<template>
  <v-row dense align-content="start" justify="center" no-gutters class="fill-height" style="width: 100%;">
    <template v-if="policies">
      <v-col cols="12" lg="8" v-for="policy in policies.data" :key="`policy-${policy.id}`">
        <v-card flat outlined>
          <v-card-title>
            {{ policy.title }}
          </v-card-title>
          <v-card-subtitle>
            Versão: {{ policy.version }} Data: {{ DateTime.fromSQL(policy.created_at).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
          </v-card-subtitle>
          <v-card-text v-html="policy.content"/>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="params.page" :length="policies.meta.last_page" :total-visible="7" @input="fetchPolicies()"/>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-policies',
    data: () => ({
      params: {
        page: 1,
        search: '',
        limit: 1
      },
      policies: null,
      DateTime: DateTime,
    }),
    async created() {
      await this.fetchPolicies();
    },
    methods: {
      async fetchPolicies() {
        const policies = await this.$axios.get(`/policies`, { params: this.params })
        this.policies = policies.data;
      },
    },
  };
</script>
