<template>
  <v-list-item two-line v-bind="$attrs">
    <v-list-item-avatar rounded :size="imageSize">
      <v-img :src="item.thumbnail ? item.thumbnail.url : require('../assets/placeholder.png')" aspect-ratio="1"/>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>{{ item.name }}</v-list-item-title>
      <v-list-item-subtitle class="caption" v-if="item.description">{{ item.description }}</v-list-item-subtitle>
      <v-list-item-subtitle>
        <template v-if="item.options.length > 0">
          <v-icon x-small color="primary" class="pb-1">icon-coin</v-icon> A partir de {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.min_amount) }}
        </template>
        <template v-else>
          <v-icon x-small color="primary" class="pb-1">icon-coin</v-icon>
          <span :style="item.promotion ? {'text-decoration': 'line-through'} : ''"> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }}</span>
          <span v-if="item.promotion"> - {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.promotion_amount) }}</span>
        </template>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="item.loyalty_pay > 0">
        <v-icon x-small color="primary" class="pb-1">icon-poker-chip</v-icon> Pontos: {{ item.loyalty_pay }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon v-if="item.promotion">
      <v-icon color="primary">mdi-sale</v-icon>
    </v-list-item-icon>
  </v-list-item>
</template>

<script>
  export default {
    inheritAttrs: false,
    name: 'a-item-tile',
    props: {
      item: Object,
      imageSize: {
        type: Number,
        default: 80
      },
    },
    mounted() {
      this.index();
    },
    methods: {
      index(){},
    },
    computed: {
      //
    },
  }
</script>
