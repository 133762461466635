import ACart from './index'
import ACartCoupon from "../../components/Cart/ACartCoupon";
import ACartPaymentMethod from "../../components/Cart/ACartPaymentMethod";
import ACartPaymentType from "../../components/Cart/ACartPaymentType";
import ACartResume from "../../components/Cart/ACartResume";
import ACartService from "../../components/Cart/ACartService";

export default [
  {
    path: '/cart',
    component: ACart,
    children: [
      {
        path: '/',
        component: ACartResume,
        name: 'cart',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'service',
        component: ACartService,
        name: 'cart_service',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'payment_type',
        component: ACartPaymentType,
        name: 'cart_payment_type',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'payment_method',
        component: ACartPaymentMethod,
        name: 'cart_payment_method',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'coupon',
        component: ACartCoupon,
        name: 'cart_coupon',
        meta: {
          requiresAuth: true
        }
      },
    ],
  },
];
