<template>
  <v-row dense align-content="start" class="fill-height">
    <v-col cols="12">
      <v-card flat dark color="primary">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title>Cupom</v-list-item-title>
            <v-list-item-subtitle>Listagem dos dados</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn disabled icon @click="$router.push({ name: 'coupons_create' })">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-col>
    <template v-if="coupons">
      <v-col cols="12">
        <v-text-field v-model="couponsSearch" prepend-inner-icon="mdi-magnify"
                      label="Pesquisar" @keyup.native.enter="searchCoupons()"/>
      </v-col>
      <v-col cols="12">
        <v-data-iterator
                    :items="coupons.data"
                    :page.sync="coupons.meta.current_page"
                    :items-per-page.sync="coupons.meta.per_page"
                    hide-default-footer
                    disable-sort>
          <template v-slot:default="props">
            <v-row dense>
              <v-col cols="12" md="4" lg="3" xl="2" v-for="item in props.items" :key="item.id">
                <v-card outlined>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title v-if="item.type === 'DECIMAL'">
                        {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.amount) }} de desconto
                      </v-list-item-title>
                      <v-list-item-title v-if="item.type === 'PERCENTAGE'">
                        {{ item.percentage }}% de desconto
                      </v-list-item-title>
                      <v-list-item-title v-if="item.type === 'DELIVERY_FREE'">
                        Frete Grátis
                      </v-list-item-title>
                      <v-list-item-title v-if="item.type === 'FIRST_ORDER_FREE'">
                        Primeira Compra Grátis
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider style="border-style: dashed"/>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-subtitle v-if="item.min_amount">
                        Valor Mínimo: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.min_amount) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.max_amount">
                        Valor Máximo: {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.max_amount) }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Quantidade: {{ item.redeemed }} de {{ item.quantity }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle>
                        Valido até {{ DateTime.fromSQL(item.valid_until).setLocale('pt-BR').toFormat('dd LLL yyyy t') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
      <v-col cols="12">
        <v-pagination v-model="page" :length="coupons.meta.last_page" :total-visible="7"></v-pagination>
      </v-col>
    </template>
  </v-row>
</template>

<script>
  import { DateTime } from "luxon";

  export default {
    name: 'a-coupon-list',
    data: () => ({
      page: 1,
      couponsSearch: '',
      couponsHeaders: [
        { text: 'Nome', value: 'name' },
        { text: 'Ação', align: 'center', width: 120, value: 'action' },
      ],
      DateTime: DateTime,
    }),
    watch: {
      page(value) {
        this.$store.dispatch('toCouponsPage', {
          user: this.user.id,
          search: this.couponsSearch,
          page: value
        });
      },
    },
    created() {
      this.fetchCoupons();
    },
    methods: {
      fetchCoupons() {
        this.$store.dispatch('fetchCoupons', {
          user: this.user.id,
        });
      },
      searchCoupons() {
        this.$store.dispatch('searchCoupons', {
          user: this.user.id,
          search: this.couponsSearch
        });
      },
      destroyCoupon(coupon) {
        if (confirm('Tem certeza que deseja excluir esse item?')) {
          this.$store.dispatch('deleteCoupon', {
            user: this.user.id,
            coupon: coupon.id
          }).then(() => {
            this.fetchCoupons();
          });
        }
      },
    },
    computed: {
      coupons() {
        return this.$store.getters.getCoupons;
      },
      user(){
        return this.$store.getters.getLoginAuthUser;
      },
    },
  };
</script>
